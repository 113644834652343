import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import { getItemsBank } from '@t12/bank/store/selectors/bank.selectors';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import {
  getPlayerID,
  getPlayer,
} from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import { getContainerItems } from '@t12/container/store/selectors/container.selectors';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import { getDialogTexts } from '@t12/dialog/store/selectors/dialog.selectors';
import { ExpeditionsDbService } from '@t12/expeditions/services/expeditions-db.service';
import { ExpeditionsActions } from '@t12/expeditions/store/actions/expeditions.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { getHudInventory } from '@t12/overlay/store/selectors/hud-display/hud-display.selectors';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { ShopActions } from '@t12/shop/store/actions/shop/shop.actions';
import { getShopItems } from '@t12/shop/store/selectors/shop.selectors';
import { WorldDbService } from '@t12/world/services/world-db/world-db.service';
import { TeleportActions } from '@t12/world/store/actions/teleport/teleport-actions';
import { WorldActions } from '@t12/world/store/actions/world/world-actions';
import {
  catchError,
  concatMap,
  filter,
  of,
  switchMap,
  take,
  withLatestFrom,
  map,
  tap,
} from 'rxjs';

@Injectable()
export class TeleportEffects {
  private _triggerWarpPlate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeleportActions.triggerWarpPlate),
      withLatestFrom(this._store.select(getPlayer)),
      concatMap(([{ warp }, player]) =>
        this._playerDbService.updatePlayer(player).pipe(
          take(1),
          switchMap(() => {
            switch (warp.type) {
              case 'world':
                return of(TeleportActions.teleportTo({}));
              case 'tower':
                return of(TeleportActions.handleTowerWarp({ warp }));
              default:
                return of(TeleportActions.teleportToFailed());
            }
          }),
        ),
      ),
    ),
  );

  private _teleportTo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeleportActions.teleportTo),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ scroll }, id]) =>
        this._worldDbService.goToNextWorld$(id, scroll).pipe(
          take(1),
          switchMap(({ world, warp }) => [
            TeleportActions.teleportToSuccess({ world, warp }),
            scroll
              ? CharactersMoveActions.setLooking({
                  id,
                  kind: CharacterKind.PLAYER,
                  looking: 'down',
                })
              : null,
          ]),
          filter(Boolean),
          catchError(() => of(TeleportActions.teleportToFailed())),
        ),
      ),
    ),
  );

  private _teleportToSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeleportActions.teleportToSuccess),
      withLatestFrom(this._store.select(getPlayerID)),
      concatMap(([{ world, warp }, id]) => [
        WorldActions.loadWorldSuccess({ world }),
        CharactersMoveActions.setPositionXY({
          id,
          kind: CharacterKind.PLAYER,
          x: warp.position.x,
          y: warp.position.y,
        }),
        TeleportActions.teleportCloseHud(),
      ]),
    ),
  );

  private _handleTowerWarp$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeleportActions.handleTowerWarp),
      map(({ warp }) => {
        switch (warp.kind) {
          case 'up':
            return ExpeditionsActions.tryAscendTower({ warp });
          case 'exit':
            return ExpeditionsActions.exitTower();
          default:
            return TeleportActions.handleTowerWarpFailed();
        }
      }),
    ),
  );

  private _handleTowerWarpFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TeleportActions.handleTowerWarpFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Téléporteur invalide, contactez un administrateur.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _teleportCloseHud$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeleportActions.teleportCloseHud),
      withLatestFrom(
        this._store.select(getShopItems),
        this._store.select(getDialogTexts),
        this._store.select(getItemsBank),
        this._store.select(getContainerItems),
        this._store.select(getHudInventory),
      ),
      switchMap(([_, shop, dialog, bank, container, hudInventory]) =>
        [
          shop ? ShopActions.closeShop() : null,
          dialog.length ? DialogActions.endConversation() : null,
          bank.length ? BankActions.closeBank() : null,
          container.length ? ContainerActions.closeContainer() : null,
          hudInventory
            ? HudDisplayActions.hideHud({ name: 'inventory' })
            : null,
        ].filter(Boolean),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _expeditionsDbService: ExpeditionsDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _store: Store,
    private readonly _worldDbService: WorldDbService,
  ) {}
}
