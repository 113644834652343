import { createReducer, on } from '@ngrx/store';
import { initialQuestState, QuestState } from '@t12/quest/store';
import { QuestActions } from '../actions/quest.actions';

export const QuestReducer = createReducer(
  initialQuestState,

  on(QuestActions.setQuests, (questState: QuestState, { questsInfos }) => ({
    ...questState,
    quests: questsInfos,
  })),

  on(
    QuestActions.addQuestSuccess,
    (questState: QuestState, { questInfos }) => ({
      ...questState,
      quests: [...questState.quests, questInfos],
    }),
  ),

  on(QuestActions.removeQuest, (questState: QuestState, { questCode }) => ({
    ...questState,
    quests: questState.quests.filter((item) => item.code !== questCode),
  })),

  on(
    QuestActions.updateGoals,
    (
      questState: QuestState,
      { questsCodes, goalKind, amount, entityCode },
    ) => ({
      ...questState,
      quests: questState.quests.map((questInfo) =>
        questsCodes.includes(questInfo.code)
          ? {
              ...questInfo,
              goals: questInfo.goals.map((goalItem) =>
                goalItem.kind === goalKind && goalItem.entityCode === entityCode
                  ? {
                      ...goalItem,
                      amount: Math.min(
                        goalItem.amount + amount,
                        goalItem.amountTotal,
                      ),
                    }
                  : goalItem,
              ),
            }
          : questInfo,
      ),
    }),
  ),
);
