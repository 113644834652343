import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getFreeInventorySlotAmountByItem } from '@t12/inventory/store/selectors/inventory.selectors';
import { JobCodeToNameEnum } from '@t12/jobs/enums/job-code-to-name.enum';
import { RecipeDbService } from '@t12/jobs/services/recipe-db/recipe-db.service';
import { CraftRecipeActions } from '@t12/jobs/store/actions/recipe/craft/craft-recipe.actions';
import {
  getPlayerJobWithCode,
  getWorkshop,
} from '@t12/jobs/store/selectors/job.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import {
  switchMap,
  map,
  take,
  tap,
  withLatestFrom,
  catchError,
  of,
} from 'rxjs';

@Injectable()
export class CraftRecipeEffects {
  private _craftRecipe$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CraftRecipeActions.craftRecipe),
      switchMap(({ recipe }) =>
        this._store.select(getPlayerJobWithCode(recipe.jobCode)).pipe(
          take(1),
          withLatestFrom(
            this._store.select(getWorkshop),
            this._store.select(
              getFreeInventorySlotAmountByItem(recipe.products[0]),
            ),
          ),
          map(([job, workshop, freeSlot]) => {
            if (!workshop)
              return CraftRecipeActions.craftRecipeFailedNoWorkshop();
            else if (!job)
              return CraftRecipeActions.craftRecipeFailedNoJob({
                jobCode: recipe.jobCode,
              });
            else if (
              !Number.isFinite(freeSlot) ||
              freeSlot < recipe.products[0].amount
            )
              return CraftRecipeActions.craftRecipeFailedNotEnoughSpace();
            else return CraftRecipeActions.craftRecipeSuccess({ recipe });
          }),
        ),
      ),
    ),
  );

  private _craftRecipeSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CraftRecipeActions.craftRecipeSuccess),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ recipe }, playerId]) =>
        this._recipeDb.craftRecipe(playerId, recipe.code).pipe(
          take(1),
          switchMap((resultCraft) => {
            if (!resultCraft.product)
              return [CraftRecipeActions.craftRecipeFailedNoProduct()];

            const { amount, name, img } = resultCraft.product;
            this._notificationService.addNotification(
              'validation',
              `+${amount} ${name}`,
              5000,
              img,
            );

            const { incJobCode, critical } = resultCraft;

            return [
              InventoryActions.removeItemsInInventory({
                items: recipe.ingredients,
              }),
              InventoryActions.addItemInInventory({
                item: resultCraft.product,
                amount: resultCraft.product.amount,
              }),
              incJobCode
                ? LocalPlayerActions.incJobXP({
                    jobCode: incJobCode,
                  })
                : null,
              critical ? CraftRecipeActions.craftRecipeCritical() : null,
            ].filter(Boolean);
          }),
          catchError(() => of(CraftRecipeActions.craftRecipeFailed())),
        ),
      ),
    ),
  );

  private _craftRecipeCritical$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CraftRecipeActions.craftRecipeCritical),
        tap(() => {
          this._notificationService.addNotification(
            'settings',
            'Fabrication critique réussie!',
          );
        }),
      ),
    { dispatch: false },
  );

  private _craftRecipeFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CraftRecipeActions.craftRecipeFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur',
          );
        }),
      ),
    { dispatch: false },
  );

  private _craftRecipeFailedNoJob$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CraftRecipeActions.craftRecipeFailedNoJob),
        tap(({ jobCode }) => {
          this._notificationService.addNotification(
            'error',
            `Vous n'avez pas le métier requis! (${JobCodeToNameEnum[jobCode]})`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _craftRecipeFailedNoWorkshop$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CraftRecipeActions.craftRecipeFailedNoWorkshop),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'êtes pas en face d'un atelier",
          );
        }),
      ),
    { dispatch: false },
  );

  private _craftRecipeFailedNotEnoughIngredients$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CraftRecipeActions.craftRecipeFailedNotEnoughIngredients),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez d'ingrédients",
          );
        }),
      ),
    { dispatch: false },
  );

  private _craftRecipeFailedNotEnoughSpace$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CraftRecipeActions.craftRecipeFailedNotEnoughSpace),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez de place dans votre sac",
          );
        }),
      ),
    { dispatch: false },
  );

  private _craftRecipeFailedNoProduct$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CraftRecipeActions.craftRecipeFailedNoProduct),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Fabrication ratée...',
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _recipeDb: RecipeDbService,
    private readonly _store: Store,
  ) {}
}
