import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isMonster } from '@t12/characters/constants/is-monster.constant';
import { isNPCOrMonster } from '@t12/characters/constants/is-npc-or-monster.constant';
import { isPlayer } from '@t12/characters/constants/is-player.constant';
import { CharacterState, Characters } from '@t12/characters/store';
import { ColorLevelDiff } from '@t12/characters/types/color-lvl.-diff.type';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import {
  DEATH_LEVEL_DIFF,
  DANGEROUS_LEVEL_DIFF,
  CAUTIOUS_LEVEL_DIFF,
  HARMLESS_LEVEL_DIFF,
} from '@t12/common/characters/enums/color-level-dff.constant';
import { IMonster } from '@t12/common/characters/interfaces/monster.interface';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { EquipmentSlot } from '@t12/common/equipments/types/equipment-slot.type';
import { positionInFrontClose } from '@t12/world/constants/position-in-front-close.constant';

const selectRoot = createFeatureSelector<CharacterState>('characters');

export const getCharacterFocusIndex = createSelector(
  selectRoot,
  ({ focusIndex }: CharacterState) => focusIndex,
);

export const getCharacters = createSelector(
  selectRoot,
  ({ characters }: CharacterState) => characters,
);

export const getMonsters = createSelector(
  getCharacters,
  (characters) => characters.filter(isMonster) as IMonster[],
);

export const getPlayers = createSelector(
  getCharacters,
  (characters) => characters.filter(isPlayer) as IPlayer[],
);

export const getPlayerById = (id: number) =>
  createSelector(getPlayers, (players: IPlayer[]): IPlayer => {
    return players.find((player) => player.id === id);
  });

export const getCharacterById = (id: number, kind: CharacterKind) =>
  createSelector(getCharacters, (characters: Characters) => {
    return characters.find(
      (character) => character.id === id && character.kind === kind,
    );
  });

export const getCharacterByCode = (codeCharacter: string | undefined) =>
  createSelector(getCharacters, (characters: Characters) => {
    if (!codeCharacter) return undefined;

    return characters
      .filter((character) => isNPCOrMonster(character))
      .find(
        (character) => (character as INPC | IMonster).code === codeCharacter,
      ) as INPC | IMonster | undefined;
  });

export const getCharacterInFrontOfPlayer = createSelector(
  getCharacters,
  (characters: Characters) => {
    if (!characters?.[0]) return undefined;
    const player = characters[0];

    const { looking, position: positionPlayer } = player;
    const position = positionInFrontClose(
      positionPlayer.x,
      positionPlayer.y,
      looking,
    );
    return characters.find(
      ({ position: { x, y } }) => x === position.x && y === position.y,
    );
  },
);

export const getPlayer = createSelector(
  getCharacters,
  (characters: Characters) =>
    characters && characters.length > 0 ? characters[0] : undefined,
);

export const getPlayerID = createSelector(
  getPlayer,
  (player: IPlayer) => player?.id,
);

export const getPlayerCanMove = createSelector(
  getPlayer,
  (player: IPlayer) => player?.canMove,
);

export const getPlayerName = createSelector(
  getPlayer,
  (player: IPlayer) => player?.name,
);

export const isLocalPlayerById = (id: number) =>
  createSelector(getPlayerID, (playerId) => playerId === id);

export const isLocalPlayerByName = (name: string) =>
  createSelector(getPlayerName, (playerName) => playerName === name);

export const getPlayerPosition = createSelector(
  getPlayer,
  (player: IPlayer) => player?.position,
);

export const getPlayerPositionInFront = createSelector(
  getPlayer,
  (player: IPlayer) =>
    positionInFrontClose(
      player?.position.x,
      player?.position.y,
      player?.looking,
    ),
);

export const getPlayerLvl = createSelector(
  getPlayer,
  (player: IPlayer) => player?.lvl,
);

export const getColorLvlDiffLocalPlayer = (lvl: number) =>
  createSelector(getPlayer, (player: IPlayer): ColorLevelDiff => {
    const levelDiff = lvl - player.lvl;
    switch (true) {
      case levelDiff >= DEATH_LEVEL_DIFF:
        return 'red';
      case levelDiff >= DANGEROUS_LEVEL_DIFF:
        return 'orange';
      case levelDiff > CAUTIOUS_LEVEL_DIFF:
        return 'yellow';
      case levelDiff > HARMLESS_LEVEL_DIFF:
        return 'green';
      default:
        return 'grey';
    }
  });

export const getPlayerStats = createSelector(
  getPlayer,
  (player: IPlayer) => player?.stats,
);

export const getPlayerEquipments = createSelector(
  getPlayer,
  (player: IPlayer) => player?.equipments,
);

export const getPlayerEquipmentBySlot = (slot: EquipmentSlot) =>
  createSelector(getPlayerEquipments, (equipments) =>
    equipments.find((equipment) => equipment.slot === slot),
  );

export const getPlayerWorldCode = createSelector(
  getPlayer,
  (player: IPlayer) => player?.worldCode,
);

export const getPlayerGold = createSelector(
  getPlayer,
  (player: IPlayer) => player?.gold,
);

export const getPlayerJobs = createSelector(
  getPlayer,
  (player: IPlayer) => player?.jobs,
);

export const getCharacterFocusPosition = createSelector(
  getCharacters,
  getCharacterFocusIndex,
  (characters, focusIndex) => characters[focusIndex]?.position,
);

export const getTarget = createSelector(
  selectRoot,
  ({ target }: CharacterState) => target,
);

export const getTargetCharacter = createSelector(
  getTarget,
  getCharacters,
  (target, characters) =>
    target
      ? characters.find((c) => c.id === target.id && c.kind === target.kind)
      : undefined,
);
