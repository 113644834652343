import { BankCombinedEffects } from '@t12/bank/constants/bank-effects.constant';
import { CharactersCombinedEffects } from '@t12/characters/constants/characters-effect.constant';
import { ChatCombinedEffects } from '@t12/chat/constants/chat-effects.constant';
import { ContainerEffects } from '@t12/container/store/effects/container.effects';
import { CreationPlayerEffects } from '@t12/creation-player/store/effects/creation-player.effects';
import { DialogEffects } from '@t12/dialog/store/effects/dialog.effects';
import { EquipmentsEffects } from '@t12/equipments/store/effects/equipments.effects';
import { EventsEffects } from '@t12/events/store/effects/events.effects';
import { ExpeditionsEffects } from '@t12/expeditions/store/effects/expeditions.effects';
import { FightEffects } from '@t12/fight/store/effects/fight.effects';
import { InventoryCombinedEffects } from '@t12/inventory/constants/inventory-effects.constant';
import { JobCombinedEffects } from '@t12/jobs/constants/job-effects.constant';
import { HotkeyEffects } from '@t12/key-commands/store/hotkey/effects/hotkey.effects';
import { HudDisplayEffects } from '@t12/overlay/store/effects/hud-display/hud-display.effects';
import { InteractionEffects } from '@t12/player/store/effects/interaction/interaction.effects';
import { LocalPlayerEffects } from '@t12/player/store/effects/local-player/local-player.effects';
import { QuestEffects } from '@t12/quest/store/effects/quest.effects';
import { ShopCombinedEffects } from '@t12/shop/constants/shop-effects.constant';
import { SocialsCombinedEffects } from '@t12/socials/constants/socials-effects.constant';
import { PlayerLoggedEffects } from '@t12/socials/store/effects/player-logged/player-logged.effects';
import { UserEffects } from '@t12/user/store/effects/user.effects';
import { WorldCombinedEffects } from '@t12/world/constants/world-effects.constant';

export const storeEffects = [
  ...BankCombinedEffects,
  ...CharactersCombinedEffects,
  ...InventoryCombinedEffects,
  ...ChatCombinedEffects,
  ...SocialsCombinedEffects,
  ...JobCombinedEffects,
  ...ShopCombinedEffects,
  ...WorldCombinedEffects,
  ContainerEffects,
  CreationPlayerEffects,
  DialogEffects,
  EquipmentsEffects,
  EventsEffects,
  FightEffects,
  HotkeyEffects,
  HudDisplayEffects,
  InteractionEffects,
  LocalPlayerEffects,
  PlayerLoggedEffects,
  QuestEffects,
  ExpeditionsEffects,
  UserEffects,
];
