import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Group } from '@t12/common/socials/interfaces/group.interface';
import { PlayerFriendRequest } from '@t12/common/socials/interfaces/player-friend-request.interface';
import { PlayerGroupInfos } from '@t12/common/socials/interfaces/player-group-infos.interface';
import { PlayerGroupRequest } from '@t12/common/socials/interfaces/player-group-request.interface';

export const SocialsGroupActions = createActionGroup({
  source: 'SOCIALS-GROUP',
  events: {
    'Get group requests': emptyProps(),
    'Get group requests failed': emptyProps(),
    'Set group requests': props<{ groupRequests: PlayerGroupRequest[] }>(),

    'Send group request': props<{ name: string }>(),
    'Send group request failed': emptyProps(),
    'Send group request failed name too short': emptyProps(),
    'Send group request failed already in group': emptyProps(),
    'Send group request failed not found': emptyProps(),
    'Send group request failed max size group': emptyProps(),
    'Send group request failed already requested': emptyProps(),
    'Send group request success': props<{ name: string }>(),
    'Add group request': props<{ groupRequest: PlayerGroupRequest }>(),

    'Get group': emptyProps(),
    'Get group failed': emptyProps(),
    'Set group': props<{ group: Group }>(),

    'Accept group request': props<{ name: string }>(),
    'Create group': props<{
      localPlayer: PlayerGroupInfos;
      playerGroup: PlayerGroupInfos;
    }>(),
    'Create group failed': emptyProps(),
    'Denied group request': props<{ name: string }>(),
    'Denied group request failed': emptyProps(),
    'Remove group request': props<{ name: string }>(),

    'Set group leader': props<{ leaderName: string }>(),
    'Set group leader success': props<{ leader: PlayerFriendRequest }>(),

    'Set group leader failed already leader': emptyProps(),
    'Set group leader failed': emptyProps(),

    'Leave group': emptyProps(),
    'Leave group success': emptyProps(),
    'Leave group failed': emptyProps(),

    'Player join group': props<{ player: PlayerGroupInfos }>(),
    'Player left group': props<{ name: string }>(),
    'Player left group success': props<{ name: string }>(),

    'Kick group player': props<{ name: string }>(),
    'Kick group player success': props<{ name: string }>(),
    'Kick group player failed': emptyProps(),
    'Kick from group': emptyProps(),

    'Set player group status online': props<{
      name: string;
      online: boolean;
    }>(),

    'Failed not in group': emptyProps(),
    'Failed not leader': emptyProps(),

    'Group disband': emptyProps(),

    'Update group infos': props<{ id: number; health: number; mana: number }>(),
  },
});
