import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { DayTime } from '@t12/common/world/enums/day-time.enum';
import { WeatherKind } from '@t12/common/world/enums/weather-kind.enum';
import { IWorld } from '@t12/common/world/interfaces/world.interface';
import { LoadingStatus } from '@t12/utils/enums/loading-status.enum';

export const WorldActions = createActionGroup({
  source: 'WORLD',
  events: {
    Init: emptyProps(),
    'Load world': emptyProps(),
    'Load world success': props<{ world: IWorld }>(),
    'Load world failed': emptyProps(),

    'Load world characters': emptyProps(),
    'Load world characters failed': emptyProps(),

    'Set world loading status': props<{ loadingStatus: LoadingStatus }>(),

    'Set weather': props<{ weather: WeatherKind }>(),
    'Set time': props<{ time: DayTime }>(),

    'Add container tile': props<{
      x: number;
      y: number;
      container: IContainer;
    }>(),
    'Add item tile': props<{
      x: number;
      y: number;
      item: Item;
    }>(),
    'Remove entity': props<{
      x: number;
      y: number;
      entity: 'item' | 'container' | 'character' | 'harvestPoint';
    }>(),
    'Remove item tile': props<{
      x: number;
      y: number;
      amount: number;
    }>(),
  },
});
