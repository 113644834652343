import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EventState } from '../index';

const selectRoot = createFeatureSelector<EventState>('events');

export const getEventId = createSelector(
  selectRoot,
  ({ id }: EventState) => id,
);

export const getEventsProgressStatus = createSelector(
  selectRoot,
  ({ progressStatus }: EventState) => progressStatus,
);

export const getCurrentEventStep = createSelector(
  selectRoot,
  ({ steps, indexStep }: EventState) => steps?.[indexStep],
);

export const getEventPlayerQuestId = createSelector(
  selectRoot,
  ({ playerQuestId }: EventState) => playerQuestId,
);
