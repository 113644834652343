import { on } from '@ngrx/store';
import { CharacterState } from '@t12/characters/store';
import { getLeftCharacter } from '@t12/common/characters/constants/get-left-character.constant';
import { getTopCharacter } from '@t12/common/characters/constants/get-top-character.constant';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { SpriteAnimationsX } from '@t12/common/characters/enums/sprite-animations-x.enum';
import { SpriteAnimationsY } from '@t12/common/characters/enums/sprite-animations-y.enum';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { Character } from '@t12/common/characters/types/character.type';
import { EquipmentSlotIndex } from '@t12/common/equipments/enums/equipment-slot-index.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { maxHealth } from '@t12/common/player/constants/max-health.constant';
import { maxMana } from '@t12/common/player/constants/max-mana.constant';
import { calculateXpForNextLevel } from '@t12/common/player/constants/next-level-xp.constant';
import { EquipmentsActions } from '@t12/equipments/store/actions/equipments.actions';
import { getKindJobByCode } from '@t12/jobs/constants/get-job-kind-by-code.constant';
import { defaultDeathPlayerState } from '@t12/player/constants/default-death-player-state.constant';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';

export const LocalPlayerHandlers: any = [
  on(
    LocalPlayerActions.loadPlayerSuccess,
    (charactersState: CharacterState, { player }) => {
      const { inventory, ...playerWithoutInventory } = player;

      const playerCharacter: IPlayer = {
        ...playerWithoutInventory,
        canMove: false,
        top: getTopCharacter(player.position.y),
        left: getLeftCharacter(player.position.x),
        spriteX: SpriteAnimationsX.IDLE_STEP_X,
        spriteY: SpriteAnimationsY[player.looking.toUpperCase()],
        firstStep: false,
        kind: CharacterKind.PLAYER,
      };

      return {
        ...charactersState,
        characters: [playerCharacter],
      };
    },
  ),

  on(LocalPlayerActions.levelUp, (charactersState: CharacterState, { xp }) => ({
    ...charactersState,
    characters: charactersState.characters.map(
      (character: Character, index: number) =>
        index === 0 && character.kind === CharacterKind.PLAYER
          ? {
              ...character,
              lvl: character.lvl + 1,
              stats: {
                ...character.stats,
                for: character.stats.for + 1,
                con: character.stats.con + 1,
                dex: character.stats.dex + 1,
                int: character.stats.int + 1,
                sag: character.stats.sag + 1,
              },
              health: maxHealth(character.stats.con + 1),
              mana: maxMana(character.stats.int + 1),
              xp: (character.xp + xp) % calculateXpForNextLevel(character.lvl),
            }
          : character,
    ),
  })),

  on(
    LocalPlayerActions.applyDeathSanction,
    (
      charactersState: CharacterState,
      { worldPosition: { worldCode, position } },
    ) => ({
      ...charactersState,
      characters: charactersState.characters.map(
        (character: Character, index: number) =>
          index === 0 && character.kind === CharacterKind.PLAYER
            ? {
                ...character,
                ...defaultDeathPlayerState(character, worldCode, position),
              }
            : character,
      ),
    }),
  ),

  on(
    LocalPlayerActions.addGold,
    (charactersState: CharacterState, { gold }) => ({
      ...charactersState,
      characters: charactersState.characters.map(
        (character: Character, index: number) =>
          index === 0
            ? { ...character, gold: character.gold + gold }
            : character,
      ),
    }),
  ),

  on(
    EquipmentsActions.equipItemSuccess,
    (charactersState: CharacterState, { item, oldEquipment }) => {
      const { stats = {} } = item;
      const { stats: oldStats = {} } = oldEquipment || {};

      return {
        ...charactersState,
        characters: charactersState.characters.map(
          (character: Character, index: number) => {
            if (index !== 0 || character.kind !== CharacterKind.PLAYER)
              return character;

            const player = character as IPlayer;
            const equipments = [...player.equipments];
            equipments[EquipmentSlotIndex[item.slot]] = { ...item, amount: 1 };

            const adjust = (stat: keyof typeof stats, multiplier = 1) =>
              ((stats[stat] || 0) - (oldStats[stat] || 0)) * multiplier;

            return {
              ...player,
              equipments,
              health: player.health + adjust('con', 4),
              mana: player.mana + adjust('int', 3),
              stats: Object.fromEntries(
                Object.entries(player.stats).map(([key, value]) => [
                  key,
                  value + adjust(key as keyof typeof stats),
                ]),
              ),
            };
          },
        ),
      };
    },
  ),

  on(
    EquipmentsActions.unEquipItemSuccess,
    (charactersState: CharacterState, { item }) => {
      const { stats = {} } = item;

      // Mise à jour des personnages
      const updatedCharacters = charactersState.characters.map(
        (character: Character, index: number) => {
          if (index !== 0 || character.kind !== CharacterKind.PLAYER) {
            return character;
          }

          const player = character as IPlayer;
          const equipments = [...player.equipments];
          equipments[EquipmentSlotIndex[item.slot]] = { amount: 0 } as Item;

          const adjust = (
            current: number,
            stat: number | undefined,
            multiplier = 1,
            min = 0,
          ) => Math.max(current - (stat || 0) * multiplier, min);

          return {
            ...player,
            equipments,
            health: adjust(player.health, stats.con, 4, 1),
            mana: adjust(player.mana, stats.int, 3, 0),
            stats: Object.fromEntries(
              Object.entries(player.stats).map(([key, value]) => [
                key,
                value - (stats[key as keyof typeof stats] || 0),
              ]),
            ),
          };
        },
      );

      return {
        ...charactersState,
        characters: updatedCharacters,
      };
    },
  ),

  on(
    LocalPlayerActions.addNewJob,
    (charactersState: CharacterState, { job }) => ({
      ...charactersState,
      characters: charactersState.characters.map(
        (character: Character, index: number) =>
          index === 0 && character.kind === CharacterKind.PLAYER
            ? {
                ...character,
                jobs: {
                  ...character.jobs,
                  [job.kind]: [...(character.jobs[job.kind] ?? []), job],
                },
              }
            : character,
      ),
    }),
  ),

  on(
    LocalPlayerActions.removeJob,
    (charactersState: CharacterState, { job }) => ({
      ...charactersState,
      characters: charactersState.characters.map(
        (character: Character, index: number) =>
          index === 0 && character.kind === CharacterKind.PLAYER
            ? {
                ...character,
                jobs: {
                  ...character.jobs,
                  [job.kind]: character.jobs[job.kind].filter(
                    (jobItem: PlayerJob) => jobItem.code !== job.code,
                  ),
                },
              }
            : character,
      ),
    }),
  ),

  on(
    LocalPlayerActions.setJobs,
    (charactersState: CharacterState, { jobs }) => ({
      ...charactersState,
      characters: charactersState.characters.map(
        (character: Character, index: number) =>
          index === 0 && character.kind === CharacterKind.PLAYER
            ? { ...character, jobs }
            : character,
      ),
    }),
  ),

  on(
    LocalPlayerActions.incJobXP,
    (charactersState: CharacterState, { jobCode }) => ({
      ...charactersState,
      characters: charactersState.characters.map(
        (character: Character, index: number) =>
          index === 0 &&
          character.kind === CharacterKind.PLAYER &&
          'jobs' in character
            ? {
                ...character,
                jobs: {
                  ...character.jobs,
                  [getKindJobByCode(jobCode)]: character.jobs[
                    getKindJobByCode(jobCode)
                  ].map((job) =>
                    job.code === jobCode ? { ...job, xp: job.xp + 1 } : job,
                  ),
                },
              }
            : character,
      ),
    }),
  ),
];
