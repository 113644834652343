import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { QuestInfos } from '@t12/common/quest/interfaces/infos-quest.interface';
import { QuestGoalKind } from '@t12/common/quest/types/quest-goal-kind.type';

export const QuestActions = createActionGroup({
  source: 'QUEST',
  events: {
    'Get quests': emptyProps(),
    'Get quests failed': emptyProps(),

    'Add quest': props<{ questCode: string; npcCode: string }>(),
    'Add quest success': props<{ questInfos: QuestInfos; npcCode: string }>(),
    'Set quests': props<{ questsInfos: QuestInfos[] }>(),

    'Validate quest': props<{ questCode: string }>(),
    'Validate quest success': props<{ questInfos: QuestInfos }>(),
    'Validate quest reward': props<{
      gold?: number;
      xp?: number;
      items?: Item[];
    }>(),

    'Validate quest failed': props<{ error: HttpErrorResponse }>(),
    'Validate quest fail api error': emptyProps(),
    'Validate quest fail not enough space': emptyProps(),

    'Get available quest': props<{ npcCode: string }>(),
    'Get available quest failed': emptyProps(),

    'Abandon quest': props<{ questsInfos: QuestInfos }>(),
    'Abandon quest fail': emptyProps(),
    'Remove quest': props<{ questCode: string }>(),

    'Update goals': props<{
      questsCodes: string[];
      amount: number;
      entityCode: string;
      goalKind: QuestGoalKind;
    }>(),
  },
});
