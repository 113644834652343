import { HudDisplayState } from '@t12/common/overlay/interfaces/hud-display-state.interface';

export const HUD_DISPLAY_FEATURE_KEY = 'hud-display';

export const initialHudDisplayState: HudDisplayState = {
  bank: false,
  barInfos: false,
  chat: false,
  chatPreview: false,
  container: false,
  dialog: false,
  inventory: false,
  jobs: false,
  notification: false,
  playerInfos: false,
  presentation: false,
  quests: false,
  settings: false,
  shop: false,
  socials: false,
  expeditions: false,
};
