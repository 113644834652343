import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  getPlayerPositionInFront,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { getOpeningSoundContainer } from '@t12/container/constants/get-opening-sound-containter.cnstant';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import { JobCodeToNameEnum } from '@t12/jobs/enums/job-code-to-name.enum';
import { HarvestDbService } from '@t12/jobs/services/harvest-db/harvest-db.service';
import { HarvestActions } from '@t12/jobs/store/actions/harvest/harvest.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { WorldActions } from '@t12/world/store/actions/world/world-actions';
import {
  concatMap,
  map,
  withLatestFrom,
  switchMap,
  tap,
  take,
  catchError,
  of,
} from 'rxjs';

@Injectable()
export class HarvestEffects {
  private _harvestSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HarvestActions.harvestSuccess),
      withLatestFrom(
        this._store.select(getPlayerID),
        this._store.select(getPlayerPositionInFront),
      ),
      switchMap(([{ harvestPoint }, playerId, position]) =>
        this._harvestDb.getHarvestResources(playerId).pipe(
          take(1),
          tap(() => {
            const openSong = getOpeningSoundContainer(harvestPoint.material);
            this._audioService.playSound('container', openSong, 'ogg');
          }),
          concatMap(({ container, incJobCode }) =>
            [
              ContainerActions.openContainerSuccess({ container }),
              HudDisplayActions.showHud({ name: 'container' }),
              incJobCode
                ? LocalPlayerActions.incJobXP({
                    jobCode: incJobCode,
                  })
                : null,
            ].filter((action) => !!action),
          ),
          catchError(() => of(HarvestActions.harvestFailed({ position }))),
        ),
      ),
    ),
  );

  private _harvestFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HarvestActions.harvestFailed),
      tap(() => {
        this._notificationService.addNotification(
          'error',
          'Ce point de récolte a expiré.',
        );
      }),
      map(({ position: { x, y } }) => {
        return WorldActions.removeEntity({ x, y, entity: 'harvestPoint' });
      }),
    ),
  );

  private _harvestFailedWrongJob$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(HarvestActions.harvestFailedWrongJob),
        tap(({ jobCode }) => {
          this._notificationService.addNotification(
            'error',
            `Vous n'avez pas le métier requis! (${JobCodeToNameEnum[jobCode]})`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _harvestFailedRequiredXpLow$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(HarvestActions.harvestFailedRequiredXpLow),
        tap(({ xp, requiredXp }) => {
          this._notificationService.addNotification(
            'error',
            `Expérience du métier trop bas! ${xp}<${requiredXp}XP`,
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _harvestDb: HarvestDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
