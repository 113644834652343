import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  getPlayerName,
  getPlayers,
  isLocalPlayerById,
  getPlayerById,
} from '@t12/characters/store/selectors/characters.selectors';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { PlayerGroupInfos } from '@t12/common/socials/interfaces/player-group-infos.interface';
import { SocialsState } from '../index';

const selectRoot = createFeatureSelector<SocialsState>('socials');

export const getSocialsActiveTab = createSelector(
  selectRoot,
  ({ activeTab }: SocialsState) => activeTab,
);

export const getFriendRequests = createSelector(
  selectRoot,
  ({ friendRequests: { displayed } }: SocialsState) => displayed,
);

export const getFriendRequestsAmount = createSelector(
  selectRoot,
  ({ friendRequests: { list } }: SocialsState) => list.length,
);

export const getFriends = createSelector(
  selectRoot,
  ({ friends: { list } }: SocialsState) => list,
);

export const getDisplayedFriends = createSelector(
  selectRoot,
  ({ friends: { displayed } }: SocialsState) => displayed,
);

export const getFriendsCurrentPage = createSelector(
  selectRoot,
  ({ friends: { currentPage } }: SocialsState) => currentPage,
);

export const getFriendsMaxPage = createSelector(
  selectRoot,
  ({ friends: { maxPage } }: SocialsState) => maxPage,
);

export const isFriend = (name: string) =>
  createSelector(
    getFriends,
    (friends) => !!friends.find((f) => f.name === name),
  );

export const getGroupRequests = createSelector(
  selectRoot,
  ({ groupRequests }: SocialsState) => groupRequests,
);

export const getGroupLeader = createSelector(
  selectRoot,
  ({ group: { leaderName } }: SocialsState) => leaderName,
);

export const isGroupLeaderByName = (name: string) =>
  createSelector(getGroupLeader, (leaderName) => {
    return leaderName === name;
  });

export const isGroupLeader = createSelector(
  getGroupLeader,
  getPlayerName,
  (leaderName, playerName) => {
    return leaderName === playerName;
  },
);

export const getPlayersGroupInfos = createSelector(
  selectRoot,
  ({ group: { playersInfos } }: SocialsState) => playersInfos,
);

export const getPlayersGroup = createSelector(
  getPlayersGroupInfos,
  getPlayers,
  (playerGroupInfos: PlayerGroupInfos[], players: IPlayer[]) =>
    playerGroupInfos
      .map(({ id }) => {
        return (
          getPlayerById(id).projector(players) ||
          playerGroupInfos.find((p) => p.id === id)
        );
      })
      .filter(Boolean),
);

export const getOtherPlayersGroupInfos = createSelector(
  selectRoot,
  ({ group: { playersInfos } }: SocialsState) => playersInfos.slice(1),
);

export const getOtherGroupPlayers = createSelector(
  getOtherPlayersGroupInfos,
  getPlayers,
  (playerGroupInfos: PlayerGroupInfos[], players: IPlayer[]) =>
    playerGroupInfos.map(({ id }) => {
      return (
        getPlayerById(id).projector(players) ||
        playerGroupInfos.find((p) => p.id === id)
      );
    }),
);

export const getPlayersGroupSize = createSelector(
  getPlayersGroupInfos,
  (group) => group.length,
);

export const isPlayerInGroup = createSelector(
  selectRoot,
  ({ group }: SocialsState) => !!group.playersInfos && !!group.leaderName,
);

export const isPlayerInGroupByName = (name: string) =>
  createSelector(getPlayersGroupInfos, (playersInfos) => {
    return !!playersInfos.find((player) => player.name === name);
  });

export const isPlayerInGroupOnline = (id: number) =>
  createSelector(getPlayersGroupInfos, (playersInfos) => {
    return playersInfos.find((player) => player.id === id).online;
  });

export const canInviteInGroup = createSelector(
  isGroupLeader,
  isPlayerInGroup,
  (isLeader, isInGroup) => isLeader || !isInGroup,
);

export const arePlayersInSameGroup = (id1: number, id2: number) =>
  createSelector(getPlayersGroupInfos, (playersInfos) => {
    const hasId1 = playersInfos.some((player) => player.id === id1);
    const hasId2 = playersInfos.some((player) => player.id === id2);
    return hasId1 && hasId2 && playersInfos.length > 0;
  });

export const getExpeditionMemberState = (id: number) =>
  createSelector(
    isLocalPlayerById(id),
    getPlayers,
    getPlayersGroupInfos,
    (isLocalPlayer, players, playersInfos) => {
      const sameWorld = !!players.find((player) => player.id === id);
      const playerGroupInfo = playersInfos.find(
        (playerInfo) => playerInfo.id === id,
      );

      if (isLocalPlayer || (sameWorld && playerGroupInfo?.online)) {
        return 'ready';
      } else if (!playerGroupInfo?.online) return 'offline';
      else return 'not-ready';
    },
  );

export const isOneMemberOffline = createSelector(
  getPlayersGroupInfos,
  (playersInfos) => playersInfos.some(({ online }) => !online),
);

export const isOneMemberInDifferentWorld = createSelector(
  getPlayersGroupInfos,
  getPlayers,
  (playersInfos, players) =>
    playersInfos.some(({ id }) => !players.some((player) => player.id === id)),
);

export const isOneMembersReadyToAscend = (position: Position) =>
  createSelector(getPlayersGroupInfos, getPlayers, (playersInfos, players) =>
    playersInfos.some(
      ({ id }) =>
        getPlayerById(id).projector(players)?.position.x === position.x &&
        position.y === position.y,
    ),
  );

export const areAllMembersReadyToAscend = (position: Position) =>
  createSelector(getPlayersGroupInfos, getPlayers, (playersInfos, players) =>
    playersInfos.every(
      ({ id }) =>
        getPlayerById(id).projector(players)?.position.x === position.x &&
        position.y === position.y,
    ),
  );
