import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  getPlayerPositionInFront,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { getOpeningSoundContainer } from '@t12/container/constants/get-opening-sound-containter.cnstant';
import { nbItemsPageContainer } from '@t12/container/constants/max-items-page-container.constant';
import { ContainerDbService } from '@t12/container/services/container-db/container-db.service';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import {
  getContainerCurrentPage,
  getContainerItems,
  getContainerKind,
  getContainer,
} from '@t12/container/store/selectors/container.selectors';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getFreeInventorySlotAmountByItem } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { SocketService } from '@t12/sockets/services/socket.service';
import { WorldActions } from '@t12/world/store/actions/world/world-actions';
import {
  catchError,
  concatMap,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class ContainerEffects {
  private _openContainer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.openContainer),
      withLatestFrom(
        this._store.select(getPlayerPositionInFront),
        this._store.select(getPlayerID),
      ),
      switchMap(([{ containerKind }, position, playerId]) =>
        this._containerDbService.getContainer(containerKind, playerId).pipe(
          take(1),
          concatMap((container) => [
            ContainerActions.openContainerSuccess({ container }),
            HudDisplayActions.showHud({ name: 'container' }),
          ]),
          catchError(() =>
            of(
              ContainerActions.openContainerFailed({
                containerKind,
                position,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  private _openContainerFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.openContainerFailed),
      tap(({ containerKind }) => {
        const entityName =
          containerKind === ContainerKind.LOOT
            ? 'Ce coffre de butin'
            : 'Ce point de récolte';
        this._notificationService.addNotification(
          'error',
          `${entityName} n'est plus disponible`,
        );
      }),
      map(({ containerKind, position: { x, y } }) =>
        WorldActions.removeEntity({
          x,
          y,
          entity:
            containerKind === ContainerKind.LOOT ? 'container' : 'harvestPoint',
        }),
      ),
    ),
  );

  private _openContainerSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ContainerActions.openContainerSuccess),
        tap(({ container }) => {
          const openSong = getOpeningSoundContainer(container.material);
          this._audioService.playSound('container', openSong, 'ogg');
        }),
      ),
    { dispatch: false },
  );

  private _pickItemContainer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.pickItemContainer),
      throttleTime(200),
      withLatestFrom(
        this._store.select(getContainerItems),
        this._store.select(getContainerCurrentPage),
      ),
      switchMap(([{ index }, items, currentPage]) => {
        const pickItem =
          items[index + (currentPage - 1) * nbItemsPageContainer];

        if (!pickItem) return of(ContainerActions.pickItemFailedNoItem({}));

        return this._store
          .select(getFreeInventorySlotAmountByItem(pickItem))
          .pipe(
            take(1),
            map((freeSlotsAmount) => {
              const pickedAmount = Math.min(freeSlotsAmount, pickItem.amount);

              if (freeSlotsAmount > 0 && freeSlotsAmount >= pickedAmount) {
                return ContainerActions.pickItemSuccess({
                  item: pickItem,
                  amount: pickedAmount,
                });
              } else {
                return ContainerActions.pickItemFailedNotEnoughPlace();
              }
            }),
          );
      }),
    ),
  );

  private _pickItemFailedNoItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.pickItemFailedNoItem),
      tap(() => {
        this._notificationService.addNotification(
          'error',
          "Ce butin n'est plus disponible",
        );
      }),
      filter(({ itemCode, amount }) => !!itemCode && !!amount),
      map(({ itemCode, amount }) =>
        ContainerActions.removeItem({ itemCode, amount }),
      ),
    ),
  );

  private _pickItemFailedNotEnoughPlace$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ContainerActions.pickItemFailedNotEnoughPlace),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez de place dans votre sac",
          );
        }),
      ),
    { dispatch: false },
  );

  private _pickItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.pickItemSuccess),

      withLatestFrom(
        this._store.select(getPlayerID),
        this._store.select(getContainerKind),
      ),
      switchMap(([{ item, amount }, playerId, containerKind]) =>
        this._containerDbService
          .pickContainerItem(item.code, playerId, containerKind)
          .pipe(
            take(1),
            tap((itemResult) => {
              this._audioService.playSound('miscs', 'drop_leather', 'ogg');
              this._notificationService.addNotification(
                'validation',
                `+${amount} ${itemResult.name}`,
                5000,
                itemResult.img,
              );
            }),
            switchMap((item: Item) => [
              ContainerActions.removeItem({ itemCode: item.code, amount }),
              InventoryActions.addItemInInventory({ item, amount }),
            ]),
            catchError(({ error }: HttpErrorResponse) => {
              const action =
                error?.message === 'NO_CONTAINER'
                  ? ContainerActions.removeContainerUnavailable()
                  : error?.message === 'NO_CONTAINER_ITEM'
                    ? ContainerActions.pickItemFailedNoItem({
                        itemCode: item.code,
                        amount,
                      })
                    : error?.message === 'NOT_ENOUGH_SPACE'
                      ? ContainerActions.pickItemFailedNotEnoughPlace()
                      : ContainerActions.pickItemFailed();

              return of(action);
            }),
          ),
      ),
    ),
  );

  private _removeContainerUnavailable$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.removeContainerUnavailable),
      withLatestFrom(this._store.select(getContainer)),
      filter(([_, container]) => !!container),
      concatMap(([_, { kind, position }]) => [
        ContainerActions.closeContainer(),
        ContainerActions.openContainerFailed({ containerKind: kind, position }),
      ]),
    ),
  );

  private _pickItemFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ContainerActions.pickItemFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _removeItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.removeItem),
      withLatestFrom(this._store.select(getContainerItems)),
      filter(([_, items]) => items.length === 0),
      withLatestFrom(
        this._store.select(getPlayerPositionInFront),
        this._store.select(getContainerKind),
        this._store.select(getPlayerID),
      ),
      switchMap(([_, { x, y }, kind, id]) => {
        const entityMap = {
          [ContainerKind.LOOT]: 'container',
          [ContainerKind.HARVEST]: 'harvestPoint',
        };
        const entity = entityMap[kind];

        if (entity === 'container')
          this._socketService.emit('player-container-remove', {
            id,
            x,
            y,
            entity,
          });

        return entity
          ? [
              HudDisplayActions.hideHud({ name: 'container' }),
              HudDisplayActions.hideHud({ name: 'inventory' }),
              WorldActions.removeEntity({ x, y, entity }),
            ]
          : [];
      }),
    ),
  );

  private _updateItemsDisplayed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        ContainerActions.openContainerSuccess,
        ContainerActions.incCurrentPage,
        ContainerActions.removeItem,
      ),
      map(() => ContainerActions.updateItemsDisplayed()),
    ),
  );

  private _closeContainer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.closeContainer),
      map(() => HudDisplayActions.hideHud({ name: 'container' })),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _containerDbService: ContainerDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _socketService: SocketService,
    private readonly _store: Store,
  ) {}
}
