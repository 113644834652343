import { EventSteps } from '@t12/common/events/type/event-step.type';
import { ProgressStatus } from '@t12/utils/enums/progress-status.enum';

export const EVENT_FEATURE_KEY = 'events';

export interface EventState {
  id: number;
  progressStatus: ProgressStatus;
  indexStep: number;
  steps?: EventSteps;
  playerQuestId?: number;
}

export const initialEventState: EventState = {
  id: -1,
  progressStatus: ProgressStatus.NOT_START,
  indexStep: 0,
};
