import { createReducer, on } from '@ngrx/store';
import { ProgressStatus } from '@t12/utils/enums/progress-status.enum';
import { EventsActions } from '../actions/events.actions';
import { initialEventState, EventState } from '../index';

export const EventsReducer = createReducer(
  initialEventState,

  on(
    EventsActions.setEventSteps,
    (eventState, { steps, id, playerQuestId }) => ({
      ...eventState,
      steps,
      id,
      progressStatus: ProgressStatus.IN_PROGRESS,
      indexStep: 0,
      playerQuestId,
    }),
  ),

  on(EventsActions.nextEvent, (eventState: EventState) => ({
    ...eventState,
    indexStep: eventState.indexStep + 1,
  })),

  on(EventsActions.endEventSuccess, (eventState) => ({
    ...eventState,
    id: -1,
    steps: undefined,
    indexStep: 0,
    progressStatus: ProgressStatus.NOT_START,
    playerQuestId: undefined,
  })),
);
