import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { noSoundHud } from '@t12/overlay/constants/no-sound-hud.constant';
import { PopOverManagerService } from '@t12/overlay/services/pop-over/pop-over-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { map, filter, withLatestFrom, tap } from 'rxjs';
import { HudDisplayActions } from '../../actions/hud-display/hud-display.actions';
import { getAllHudDisplay } from '../../selectors/hud-display/hud-display.selectors';

@Injectable()
export class HudDisplayEffects {
  private _displayHud$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        HudDisplayActions.showHud,
        HudDisplayActions.toggleHud,
        HudDisplayActions.hideHud,
      ),
      withLatestFrom(this._store.select(getAllHudDisplay)),
      map(([{ name: hud }, allHudDisplay]) =>
        HudDisplayActions.handleDisplay({ display: allHudDisplay[hud], hud }),
      ),
    ),
  );

  private _hudPlaySound$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(HudDisplayActions.showHud, HudDisplayActions.toggleHud),
        withLatestFrom(this._store.select(getAllHudDisplay)),
        filter(([{ name: hud }, allHudDisplay]) => allHudDisplay[hud]),
        tap(([{ name: hud }]) => {
          if (!noSoundHud.includes(hud))
            this._audioService.playSound('interfaces/buttons', 'correct');
        }),
      ),
    { dispatch: false },
  );

  private _handleDisplay$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HudDisplayActions.handleDisplay),
      map(({ display, hud }) => {
        switch (hud) {
          case 'inventory':
            return HudDisplayActions.handleDisplayInventory({ display });
          case 'container':
            return HudDisplayActions.handleDisplayContainer({ display });
          case 'playerInfos':
            return HudDisplayActions.handleDisplayPlayerInfos({ display });
          case 'jobs':
            return HudDisplayActions.handleDisplayJobs({ display });
          case 'quests':
            return HudDisplayActions.handleDisplayQuests({ display });
          case 'chat':
            return HudDisplayActions.handleDisplayChat({ display });
          default:
            return null;
        }
      }),
      filter((action) => !!action),
    ),
  );

  private _handleDisplayInventory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HudDisplayActions.handleDisplayInventory),
      map(({ display }) => {
        if (display) return HudDisplayActions.hideHud({ name: 'quests' });
        else return InventoryActions.resetActiveItem();
      }),
    ),
  );

  private _handleDisplayContainer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HudDisplayActions.handleDisplayContainer),
      map(({ display }) => {
        if (display) {
          return HudDisplayActions.showHud({ name: 'inventory' });
        } else {
          this._popoverService.deletePopOver();
          return HudDisplayActions.hideHud({ name: 'inventory' });
        }
      }),
    ),
  );

  private _handleDisplayPlayerInfos$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HudDisplayActions.handleDisplayPlayerInfos),
      tap(({ display }) => {
        if (!display) this._popoverService.deletePopOver();
      }),
      filter(({ display }) => display),
      map(() => HudDisplayActions.hideHud({ name: 'jobs' })),
    ),
  );

  private _handleDisplayJobs$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HudDisplayActions.handleDisplayJobs),
      filter(({ display }) => display),
      map(() => HudDisplayActions.hideHud({ name: 'playerInfos' })),
    ),
  );

  private _handleDisplayQuests$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HudDisplayActions.handleDisplayQuests),
      filter(({ display }) => display),
      map(() => HudDisplayActions.hideHud({ name: 'inventory' })),
    ),
  );

  private _handleDisplayChat$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HudDisplayActions.handleDisplayChat),
      filter(({ display }) => display),
      map(() => ChatActions.resetNewMessage()),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _popoverService: PopOverManagerService,
    private readonly _store: Store,
  ) {}
}
