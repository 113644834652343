import { createReducer, on } from '@ngrx/store';
import { isNPCOrMonster } from '@t12/characters/constants/is-npc-or-monster.constant';
import { CharactersAttackHandlers } from '@t12/characters/store/reducers/attack/characters-attack.reducer';
import { CharactersMoveHandlers } from '@t12/characters/store/reducers/move/characters-move.reducer';
import { getLeftCharacter } from '@t12/common/characters/constants/get-left-character.constant';
import { getTopCharacter } from '@t12/common/characters/constants/get-top-character.constant';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { SpriteAnimationsX } from '@t12/common/characters/enums/sprite-animations-x.enum';
import { SpriteAnimationsY } from '@t12/common/characters/enums/sprite-animations-y.enum';
import { Character } from '@t12/common/characters/types/character.type';
import { maxHealth } from '@t12/common/player/constants/max-health.constant';
import { maxMana } from '@t12/common/player/constants/max-mana.constant';
import { calculateXpForNextLevel } from '@t12/common/player/constants/next-level-xp.constant';
import { LocalPlayerHandlers } from '@t12/player/store/reducers/local-player/local-player.reducer';
import { CharactersActions } from '../../actions/characters/characters.actions';
import { CharacterState, initialCharactersState } from '../../index';

export const CharactersReducer = createReducer(
  initialCharactersState,

  ...LocalPlayerHandlers,
  ...CharactersMoveHandlers,
  ...CharactersAttackHandlers,

  on(
    CharactersActions.addCharacterSuccess,
    (state: CharacterState, { character }) => {
      const newCharacter = {
        ...character,
        canMove: true,
        top: getTopCharacter(character.position.y),
        left: getLeftCharacter(character.position.x),
        spriteX: SpriteAnimationsX.IDLE_STEP_X,
        spriteY: SpriteAnimationsY[character.looking.toUpperCase()],
        firstStep: false,
        kind: character.kind,
      } as Character;

      return {
        ...state,
        characters: [...state.characters, newCharacter],
      };
    },
  ),

  on(
    CharactersActions.removeCharacterByIdSuccess,
    (state: CharacterState, { id, kind }) => {
      const updatedCharacters = state.characters.filter(
        (character) => !(character.id === id && character.kind === kind),
      );

      return {
        ...state,
        characters: updatedCharacters,
      };
    },
  ),

  on(
    CharactersActions.setCharacters,
    (charactersState: CharacterState, { characters }) => ({
      ...charactersState,
      characters: [charactersState.characters[0], ...characters],
    }),
  ),

  on(CharactersActions.resetCharacters, (charactersState: CharacterState) => ({
    ...charactersState,
    characters: [charactersState.characters[0]],
  })),

  on(
    CharactersActions.deleteAllCharacters,
    (charactersState: CharacterState) => ({
      ...charactersState,
      characters: [],
    }),
  ),

  on(
    CharactersActions.characterIsDead,
    (charactersState: CharacterState, { id, kind }) => ({
      ...charactersState,
      characters: charactersState.characters.map((character: Character) =>
        character.id === id && character.kind === kind
          ? { ...character, health: 0 }
          : character,
      ),
    }),
  ),

  on(
    CharactersActions.addHealth,
    (charactersState: CharacterState, { id, kind, health }) => ({
      ...charactersState,
      characters: charactersState.characters.map((character) =>
        character.id === id && character.kind === kind
          ? {
              ...character,
              health:
                character.health + health < 0
                  ? 0
                  : Math.min(
                      character.health + health,
                      maxHealth(character.stats.con),
                    ),
            }
          : character,
      ),
    }),
  ),

  on(
    CharactersActions.addMana,
    (charactersState: CharacterState, { id, kind, mana }) => ({
      ...charactersState,
      characters: charactersState.characters.map((character) =>
        character.id === id && character.kind === kind
          ? {
              ...character,
              mana:
                character.mana + mana < 0
                  ? 0
                  : Math.min(
                      character.mana + mana,
                      maxMana(character.stats.int),
                    ),
            }
          : character,
      ),
    }),
  ),

  on(
    CharactersActions.addHealthMana,
    (charactersState: CharacterState, { id, kind, health, mana }) => ({
      ...charactersState,
      characters: charactersState.characters.map((character) =>
        character.id === id && character.kind === kind
          ? {
              ...character,
              health:
                character.health + health < 0
                  ? 0
                  : Math.min(
                      character.health + health,
                      maxHealth(character.stats.con),
                    ),
              mana:
                character.mana + mana < 0
                  ? 0
                  : Math.min(
                      character.mana + mana,
                      maxMana(character.stats.int),
                    ),
            }
          : character,
      ),
    }),
  ),

  on(CharactersActions.levelUp, (charactersState: CharacterState, { id }) => ({
    ...charactersState,
    characters: charactersState.characters.map((character) =>
      character.id === id && character.kind === CharacterKind.PLAYER
        ? {
            ...character,
            lvl: character.lvl + 1,
            stats: {
              ...character.stats,
              for: character.stats.for + 1,
              con: character.stats.con + 1,
              dex: character.stats.dex + 1,
              int: character.stats.int + 1,
              sag: character.stats.sag + 1,
            },
            health: maxHealth(character.stats.con + 1),
            mana: maxMana(character.stats.int + 1),
          }
        : character,
    ),
  })),

  on(
    CharactersActions.addXp,
    (charactersState: CharacterState, { id, xp }) => ({
      ...charactersState,
      characters: charactersState.characters.map((character) =>
        character.id === id && character.kind === CharacterKind.PLAYER
          ? {
              ...character,
              xp: (character.xp + xp) % calculateXpForNextLevel(character.lvl),
            }
          : character,
      ),
    }),
  ),

  on(
    CharactersActions.setQuestIcon,
    (charactersState: CharacterState, { codeCharacter, questIcon }) => {
      const characters = charactersState.characters.map(
        (character: Character) =>
          character.kind === CharacterKind.NPC &&
          character.code === codeCharacter
            ? { ...character, questIcon }
            : character,
      );

      return {
        ...charactersState,
        characters,
      };
    },
  ),

  on(
    CharactersActions.setCharacterFocus,
    (charactersState: CharacterState, { code }) => ({
      ...charactersState,
      focusIndex: code
        ? charactersState.characters.findIndex(
            (character: Character) =>
              isNPCOrMonster(character) && character.code === code,
          )
        : 0,
    }),
  ),

  on(
    CharactersActions.setTargetSuccess,
    (charactersState: CharacterState, { target }) => ({
      ...charactersState,
      target,
    }),
  ),

  on(CharactersActions.resetTarget, (charactersState: CharacterState) => ({
    ...charactersState,
    target: undefined,
  })),
);
