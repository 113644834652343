import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Character } from '@t12/common/characters/types/character.type';
import { WarpPlayer } from '@t12/common/world/interfaces/warp-player.interface';
import { IWorldWarp } from '@t12/common/world/interfaces/world-warp.interface';
import { IWorld } from '@t12/common/world/interfaces/world.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorldDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  // Argument : ID joueur, ID personnage
  // Résultat : Retourne les infos d'un personnage stocké en base de donnée
  public getWorld$(playerId: number): Observable<IWorld> {
    return this._http.get(`${environment(`world/${playerId}`).uri}`, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<IWorld>;
  }

  public getWorldCharacters$(playerId: number): Observable<Character[]> {
    return this._http.get<Character[]>(
      `${environment(`world/${playerId}/characters`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }

  public goToNextWorld$(
    playerId: number,
    scrollCode?: string,
  ): Observable<{ world: IWorld; warp: IWorldWarp }> {
    const warpPlayerDto: WarpPlayer = {
      playerId,
      scroll: scrollCode,
    };

    return this._http.patch(`${environment(`world/warp`).uri}`, warpPlayerDto, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<{ world: IWorld; warp: IWorldWarp }>;
  }
}
