import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { isNPCOrMonster } from '@t12/characters/constants/is-npc-or-monster.constant';
import { isPlayer } from '@t12/characters/constants/is-player.constant';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import {
  getPlayerWorldCode,
  isLocalPlayerById,
  getCharacterById,
} from '@t12/characters/store/selectors/characters.selectors';
import { IMonster } from '@t12/common/characters/interfaces/monster.interface';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { delayRemoveActiveTarget } from '@t12/fight/constants/delay-remove-active-target.constant';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import {
  getQuestsInfos,
  getQuestsInfosByCode,
} from '@t12/quest/store/selectors/quest.selectors';
import { SocialsGroupActions } from '@t12/socials/store/actions/group/socials-group.actions';
import { isPlayerInGroupByName } from '@t12/socials/store/selectors/socials.selectors';
import {
  filter,
  withLatestFrom,
  mergeMap,
  map,
  switchMap,
  take,
  of,
  EMPTY,
  delay,
} from 'rxjs';

@Injectable()
export class CharacterEffects {
  private _setCharacters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.setCharacters),
      withLatestFrom(this._store.select(getQuestsInfos)),
      filter(([_, questsInfos]) => !!questsInfos),
      mergeMap(([{ characters }, questsInfos]) => {
        const charactersCode = characters
          .filter(isNPCOrMonster)
          .map((character) => character.code);

        return questsInfos.flatMap(({ code: questCode, goals }) =>
          goals
            .filter(
              (goal) =>
                goal.kind === 'exploration' &&
                goal.amount < goal.amountTotal &&
                charactersCode.includes(goal.entityCode),
            )
            .map((goal) =>
              QuestActions.updateGoals({
                questsCodes: [questCode],
                goalKind: 'exploration',
                amount: 1,
                entityCode: goal.entityCode,
              }),
            ),
        );
      }),
    ),
  );

  private _addCharacter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.addCharacter),
      withLatestFrom(this._store.select(getPlayerWorldCode)),
      filter(
        ([{ character }, worldCode]) =>
          isNPCOrMonster(character) && character.worldCode === worldCode,
      ),
      switchMap(([action, _worldCode]) => {
        const character = action.character as INPC | IMonster;

        return this._store
          .select(getQuestsInfosByCode(character.questCode))
          .pipe(
            take(1),
            filter((quest) => !character.questCode || !!quest),
            map(() => CharactersActions.addCharacterSuccess({ character })),
          );
      }),
    ),
  );

  private _removeCharacterById$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.removeCharacterById),
      switchMap(({ id, kind }) =>
        this._store.select(getCharacterById(id, kind)).pipe(
          take(1),
          switchMap((character) => {
            const actions: Action[] = [
              CharactersActions.removeCharacterByIdSuccess({ id, kind }),
            ];

            if (isPlayer(character)) {
              return this._store
                .select(isPlayerInGroupByName(character.name))
                .pipe(
                  take(1),
                  filter((isInGroup) => isInGroup),
                  map((isInGroup) => {
                    actions.push(
                      SocialsGroupActions.updateGroupInfos({
                        id,
                        health: character.health,
                        mana: character.mana,
                      }),
                    );

                    return actions;
                  }),
                );
            }

            return of(actions);
          }),
          mergeMap((actions) => actions),
        ),
      ),
    ),
  );

  private _setTarget$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.setTarget),
      switchMap(({ target, delayEnable }) =>
        this._store.select(isLocalPlayerById(target.id)).pipe(
          take(1),
          filter((isLocalPlayer) => !isLocalPlayer),
          map(() =>
            CharactersActions.setTargetSuccess({ target, delayEnable }),
          ),
        ),
      ),
    ),
  );

  private _setTargetSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.setTargetSuccess),
      switchMap(({ delayEnable }) => {
        if (delayEnable)
          return of(CharactersActions.resetTarget()).pipe(
            delay(delayRemoveActiveTarget),
          );
        else return EMPTY;
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
