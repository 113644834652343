import { ITarget } from '@t12/characters/interfaces/target.interface';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { Character } from '@t12/common/characters/types/character.type';

export const PLAYER_FEATURE_KEY = 'characters';

export type Characters = [IPlayer, ...Character[]] | IPlayer[];
export type CharacterState = {
  focusIndex: number;
  target?: ITarget;
  characters: Characters;
};

export const initialCharactersState: CharacterState = {
  focusIndex: 0,
  characters: [],
};
