import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { delayActionAnimation } from '@t12/characters/constants/delay-animation.constant';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import { CharactersFightActions } from '@t12/characters/store/actions/fight/characters-fight.actions';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import {
  getCharacterById,
  getPlayerID,
  getPlayer,
} from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { Character } from '@t12/common/characters/types/character.type';
import { HitKind } from '@t12/common/fight/types/hit-kind.type';
import { costManaMagicAttack } from '@t12/common/player/constants/cost-mana-magic-attack.constant';
import { FightDbService } from '@t12/fight/services/fight-db/fight-db.service';
import { FightActions } from '@t12/fight/store/actions/fight.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { arePlayersInSameGroup } from '@t12/socials/store/selectors/socials.selectors';
import {
  concatMap,
  delay,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CharactersAttackActions } from '../../actions/attack/characters-attack.actions';

@Injectable()
export class AttackCharacterEffects {
  private _attack$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersAttackActions.attack),
      switchMap((action) =>
        this._store
          .select(getCharacterById(action.id, action.characterKind))
          .pipe(
            take(1),
            map((character) => ({ action, character })),
          ),
      ),
      filter(
        ({ character, action: { attackKind } }) =>
          (attackKind === 'magic' || attackKind === 'physic') &&
          character.health > 0,
      ),
      map(({ action: { attackKind, emitDmg }, character }) =>
        attackKind === 'physic'
          ? CharactersAttackActions.attackPhysic({ character, emitDmg })
          : CharactersAttackActions.attackMagic({ character, emitDmg }),
      ),
    ),
  );

  private _attackPhysic$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersAttackActions.attackPhysic),
      map(({ character, emitDmg }) =>
        CharactersAttackActions.attackFirstStep({
          character,
          attackKind: 'physic',
          emitDmg,
        }),
      ),
    ),
  );

  private _attackMagic$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersAttackActions.attackMagic),
      map(({ character, emitDmg }) => {
        const costMana = costManaMagicAttack(character.stats.int);
        if (character.mana < costMana)
          return CharactersAttackActions.attackMagicFailedNotEnoughMana({
            id: character.id,
            costMana,
          });
        else
          return CharactersAttackActions.attackFirstStep({
            character,
            attackKind: 'magic',
            emitDmg,
          });
      }),
    ),
  );

  private _attackMagicFailedNotEnoughMana$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CharactersAttackActions.attackMagicFailedNotEnoughMana),
        withLatestFrom(this._store.select(getPlayerID)),
        filter(([{ id }, playerId]) => id === playerId),
        tap(([{ costMana }]) => {
          this._notificationService.addNotification(
            'error',
            `Vous n'avez pas assez de mana! (${costMana} PM requis)`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _attackFirstStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersAttackActions.attackFirstStep),
      delay(300),
      map(({ character, attackKind, emitDmg }) =>
        CharactersAttackActions.attackSecondStep({
          character,
          attackKind,
          emitDmg,
        }),
      ),
    ),
  );

  private _attackSecondStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersAttackActions.attackSecondStep),
      delay(100),
      map(({ character, attackKind }) =>
        CharactersAttackActions.attackThirdStep({ character, attackKind }),
      ),
    ),
  );

  private _attackThirdStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersAttackActions.attackThirdStep),
      delay(delayActionAnimation),
      map(({ character }) =>
        CharactersMoveActions.idleStep({ character, triggerTile: false }),
      ),
    ),
  );

  private _attackTryToHit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersAttackActions.attackSecondStep),
      filter(({ emitDmg }) => Boolean(emitDmg)),
      withLatestFrom(this._store.select(getPlayerID)),
      concatMap(([{ attackKind }, playerId]) =>
        this._fightDbService.attackCharacter(playerId, attackKind).pipe(
          take(1),
          concatMap((attackResult) => {
            const {
              target,
              hit: { damage, kind: hitKind },
              reward,
            } = attackResult;

            if (target?.kind === CharacterKind.NPC) return [];

            const actions: Action[] = [
              CharactersAttackActions.attackSound({
                attackKind,
                hit: hitKind,
              }),
              target ? FightActions.displayFightLog({ attackResult }) : null,
            ].filter(Boolean);

            if (target) {
              actions.push(
                CharactersAttackActions.attackDealDamage({
                  targetId: target.id,
                  targetKind: target.kind,
                  damage,
                }),
              );

              if (target.dead) {
                actions.push(
                  CharactersActions.characterIsDead({
                    id: target.id,
                    kind: target.kind,
                  }),
                );
                actions.push(CharactersActions.resetTarget());

                if (reward) {
                  actions.push(
                    CharactersFightActions.rewardFight({
                      target: {
                        kind: target.kind,
                        code: target.code,
                      },
                      reward,
                    }),
                  );
                }
              }
            }

            return actions;
          }),
        ),
      ),
    ),
  );

  private _characterIsDead$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.characterIsDead),
      delay(1000),
      filter(({ kind }) => kind !== CharacterKind.PLAYER),
      map(({ id, kind }) =>
        CharactersActions.removeCharacterById({ id, kind }),
      ),
    ),
  );

  private _attackSound$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CharactersAttackActions.attackSound),
        tap(({ attackKind, hit }) => {
          const soundType = attackKind === 'physic' ? 'hit' : 'spell';
          const randPhysic = (limit: number) =>
            Math.floor(Math.random() * limit);

          if (attackKind === 'physic') {
            const impactSoundMap: Partial<Record<HitKind, number>> = {
              block: 4,
              parry: 5,
              crit: 3,
              hit: 8,
            };

            if (hit in impactSoundMap) {
              const rand = randPhysic(impactSoundMap[hit]);
              this._audioService.playSound('impacts', `${hit}_${rand}`);
              return;
            }
          }

          const rand = randPhysic(attackKind === 'physic' ? 8 : 2);
          this._audioService.playSound('impacts', `${soundType}_${rand}`);
        }),
      ),
    { dispatch: false },
  );

  private _attackDealDamage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersAttackActions.attackDealDamage),
      switchMap(({ targetId, targetKind }) =>
        this._store.select(getCharacterById(targetId, targetKind)).pipe(
          take(1),
          map((target: Character) =>
            CharactersActions.setTarget({
              target: { id: target.id, kind: target.kind },
            }),
          ),
        ),
      ),
    ),
  );

  private _characterAttack$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersAttackActions.characterAttack),
      withLatestFrom(this._store.select(getPlayer)),
      switchMap(([{ attackCharacterResult }, player]) => {
        const { target, attacker, hit, reward } = attackCharacterResult;

        return this._store
          .select(arePlayersInSameGroup(attacker.id, player.id))
          .pipe(
            take(1),
            concatMap((areInSameGroup) => {
              const actions: Action[] = [
                CharactersAttackActions.attackSound({
                  attackKind: hit.attackKind,
                  hit: hit.kind,
                }),
                CharactersAttackActions.attack({
                  id: attacker.id,
                  characterKind: attacker.kind,
                  attackKind: hit.attackKind,
                }),
                (target?.name === player.name || areInSameGroup) &&
                  FightActions.displayFightLog({
                    attackResult: attackCharacterResult,
                  }),
                target &&
                  CharactersActions.addHealth({
                    id: target.id,
                    kind: target.kind,
                    health: -hit.damage,
                  }),
              ].filter(Boolean);

              if (target) {
                if (
                  target.kind === CharacterKind.PLAYER &&
                  target.dead &&
                  target.id === player.id
                ) {
                  actions.push(LocalPlayerActions.playerDeath());
                }

                if (target.dead && target.kind !== CharacterKind.PLAYER) {
                  actions.push(
                    CharactersActions.characterIsDead({
                      id: target.id,
                      kind: target.kind,
                    }),
                  );
                }
                if (
                  target.dead &&
                  reward &&
                  (attacker.id === player.id || areInSameGroup)
                ) {
                  actions.push(
                    CharactersFightActions.rewardFight({
                      target: { kind: target.kind, code: target.code },
                      reward,
                    }),
                  );
                }
              }

              return actions;
            }),
          );
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _fightDbService: FightDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
