import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { WeatherKind } from '@t12/common/world/enums/weather-kind.enum';
import { WorldActions } from '@t12/world/store/actions/world/world-actions';
import { filter } from 'rxjs';
import { SocketService } from '../../socket.service';

@Injectable({
  providedIn: 'root',
})
export class WeatherSocketService {
  constructor(
    private readonly _store: Store,
    private readonly _socketService: SocketService,
  ) {}

  public init() {
    this._listenForWeatherWorldActions();
  }

  private _listenForWeatherWorldActions() {
    const weatherWorld$ = this._socketService
      .fromEvent<WeatherKind>('weather-world')
      .pipe(filter((weather) => !!weather))
      .subscribe((weather) => {
        this._store.dispatch(WorldActions.setWeather({ weather }));
      });
    this._socketService.addSubscription(weatherWorld$);
  }
}
