import { on } from '@ngrx/store';
import { CharacterState } from '@t12/characters/store';
import { CharactersAttackActions } from '@t12/characters/store/actions/attack/characters-attack.actions';
import { SpriteAnimationsX } from '@t12/common/characters/enums/sprite-animations-x.enum';
import { Character } from '@t12/common/characters/types/character.type';
import { costManaMagicAttack } from '@t12/common/player/constants/cost-mana-magic-attack.constant';

export const CharactersAttackHandlers = [
  on(
    CharactersAttackActions.attackFirstStep,
    (state: CharacterState, { character, attackKind }) => ({
      ...state,
      characters: state.characters.map((characterF: Character) =>
        characterF.id === character.id && characterF.kind === character.kind
          ? {
              ...characterF,
              spriteX:
                attackKind === 'physic'
                  ? SpriteAnimationsX.FIRST_PHYSICAL_ATTACK_X
                  : SpriteAnimationsX.FIRST_MAGICAL_ATTACK_X,
              canMove: false,
              mana:
                attackKind === 'magic'
                  ? character.mana - costManaMagicAttack(character.stats.int)
                  : character.mana,
            }
          : characterF,
      ),
    }),
  ),

  on(
    CharactersAttackActions.attackSecondStep,
    (state: CharacterState, { character, attackKind }) => ({
      ...state,
      characters: state.characters.map((characterF: Character) =>
        characterF.id === character.id && characterF.kind === character.kind
          ? {
              ...characterF,
              spriteX:
                attackKind === 'physic'
                  ? SpriteAnimationsX.SECOND_PHYSICAL_ATTACK_X
                  : SpriteAnimationsX.SECOND_MAGICAL_ATTACK_X,
            }
          : characterF,
      ),
    }),
  ),

  on(
    CharactersAttackActions.attackThirdStep,
    (state: CharacterState, { character, attackKind }) => ({
      ...state,
      characters: state.characters.map((characterF: Character) =>
        characterF.id === character.id && characterF.kind === character.kind
          ? {
              ...characterF,
              spriteX:
                attackKind === 'physic'
                  ? SpriteAnimationsX.LAST_PHYSICAL_ATTACK_X
                  : SpriteAnimationsX.LAST_MAGICAL_ATTACK_X,
            }
          : characterF,
      ),
    }),
  ),

  on(
    CharactersAttackActions.attackDealDamage,
    (state: CharacterState, { targetId, targetKind, damage }) => ({
      ...state,
      characters: state.characters.map((character: Character) =>
        character.id === targetId && targetKind === character.kind
          ? {
              ...character,
              health: Math.max(character.health - damage, 0),
            }
          : character,
      ),
    }),
  ),
];
