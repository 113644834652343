import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { LaunchExpedition } from '@t12/common/expeditions/interfaces/launch-expedition.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExpeditionsDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  public launchExpedition(playerId: number): Observable<Position> {
    const body: LaunchExpedition = { playerId };

    return this._http.post<Position>(
      `${environment('expeditions/launch').uri}`,
      body,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }

  public ascendTower(playerId: number): Observable<Position> {
    const body: LaunchExpedition = { playerId };

    return this._http.post<Position>(
      `${environment('expeditions/ascend').uri}`,
      body,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }

  public exitExpedition(playerId: number): Observable<Position> {
    const body: LaunchExpedition = { playerId };

    return this._http.post<Position>(
      `${environment('expeditions/exit').uri}`,
      body,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }
}
