import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { DayTime } from '@t12/common/world/enums/day-time.enum';
import { EventsActions } from '@t12/events/store/actions/events.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { getQuestsInfos } from '@t12/quest/store/selectors/quest.selectors';
import { defaultMusic } from '@t12/settings/constants/default-music-name.constant';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { delayResetBlackoutIn } from '@t12/world/constants/delay-reset-blackout-in.constant';
import { delayResetBlackoutOut } from '@t12/world/constants/delay-reset-blackout-out.constant';
import { WorldDbService } from '@t12/world/services/world-db/world-db.service';
import { TeleportActions } from '@t12/world/store/actions/teleport/teleport-actions';
import { WorldActions } from '@t12/world/store/actions/world/world-actions';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class WorldEffects {
  private _loadWorld$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorld),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, playerId]) =>
        this._worldDbService.getWorld$(playerId).pipe(
          take(1),
          map((world) => WorldActions.loadWorldSuccess({ world })),
          catchError(() => of(WorldActions.loadWorldFailed())),
        ),
      ),
    ),
  );

  private _loadWorldFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(WorldActions.loadWorldFailed, TeleportActions.teleportToFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Impossible de charger le monde, contactez un administrateur.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _loadWorldSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorldSuccess),
      tap(({ world }) => {
        this._audioService.playBackgroundMusic(world.music || defaultMusic);
      }),
      switchMap(({ world: { characters, events } }) => {
        const actions: Action[] = [
          CharactersActions.setCharacters({ characters }),
        ];

        if (events?.id)
          actions.push(
            EventsActions.setEventSteps({
              steps: events.steps,
              id: events.id,
              playerQuestId: events.playerQuestId,
            }),
          );

        return of(actions);
      }),
      mergeMap((actions) => actions),
    ),
  );

  private _loadWorldCharacter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorldCharacters),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, playerId]) =>
        this._worldDbService.getWorldCharacters$(playerId).pipe(
          take(1),
          map((characters) => CharactersActions.setCharacters({ characters })),
          catchError(() => of(WorldActions.loadWorldCharactersFailed())),
        ),
      ),
    ),
  );

  private _loadWorldCharactersFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(WorldActions.loadWorldCharactersFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Impossible de récupérer les personnages du monde.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _goalWorldExploration$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorldSuccess),
      withLatestFrom(this._store.select(getQuestsInfos)),
      map(([{ world }, questsInfos]) => ({
        worldCode: world.code,
        questsCodes: questsInfos
          .filter(({ goals }) =>
            goals.some(
              ({ kind, entityCode, entityKind, amount, amountTotal }) =>
                kind === 'exploration' &&
                entityCode === world.code &&
                entityKind === 'world' &&
                amount !== amountTotal,
            ),
          )
          .map(({ code }) => code),
      })),
      filter(({ questsCodes }) => !!questsCodes.length),
      map(({ questsCodes, worldCode }) =>
        QuestActions.updateGoals({
          questsCodes,
          goalKind: 'exploration',
          amount: 1,
          entityCode: worldCode,
        }),
      ),
    ),
  );

  private _resetWorld$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorld, TeleportActions.teleportTo),
      map(() => CharactersActions.resetCharacters()),
    ),
  );

  private _blackoutOut$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.setTime),
      filter(({ time }) => time === DayTime.BLACKOUT_OUT),
      delay(delayResetBlackoutOut),
      map(() => {
        return WorldActions.setTime({ time: DayTime.DAY });
      }),
    ),
  );

  private _blackoutIn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.setTime),
      filter(({ time }) => time === DayTime.BLACKOUT_IN),
      delay(delayResetBlackoutIn),
      map(() => {
        return WorldActions.setTime({ time: DayTime.DAY });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
    private readonly _worldDbService: WorldDbService,
  ) {}
}
