import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventQuestStatus } from '@t12/common/events/interfaces/event-quest-status.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  public updateEventStatusQuest(
    playerId: number,
    eventId: number,
    playerQuestId: number,
  ): Observable<void> {
    const depositDto: EventQuestStatus = {
      playerId,
      id: eventId,
      playerQuestId,
    };

    return this._http.patch<void>(
      `${environment(`events/player`).uri}`,
      depositDto,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }
}
