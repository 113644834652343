import { on } from '@ngrx/store';
import { directionMap } from '@t12/characters/constants/direction-map.constant';
import { CharacterState } from '@t12/characters/store';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import { getLeftCharacter } from '@t12/common/characters/constants/get-left-character.constant';
import { getTopCharacter } from '@t12/common/characters/constants/get-top-character.constant';
import { SpriteAnimationsX } from '@t12/common/characters/enums/sprite-animations-x.enum';
import { SpriteAnimationsY } from '@t12/common/characters/enums/sprite-animations-y.enum';
import { Character } from '@t12/common/characters/types/character.type';
import { Looking } from '@t12/common/characters/types/looking.type';
import { maxTilesX } from '@t12/common/world/constants/max-tiles-x.constant';
import { maxTilesY } from '@t12/common/world/constants/max-tiles-y.constant';

export const CharactersMoveHandlers = [
  on(
    CharactersMoveActions.move,
    (state: CharacterState, { id, kind, direction }) => ({
      ...state,
      characters: state.characters.map((character: Character) =>
        character.id === id && character.kind === kind
          ? {
              ...character,
              looking: direction,
              spriteY: SpriteAnimationsY[direction.toUpperCase()],
            }
          : character,
      ),
    }),
  ),

  on(
    CharactersMoveActions.moveStep,
    (state: CharacterState, { character: { id, kind }, direction }) => {
      const { left, top, x, y } = directionMap[direction];

      return {
        ...state,
        characters: state.characters.map((character: Character) => {
          if (character.id === id && character.kind === kind) {
            const newX = character.position.x + x;
            const newY = character.position.y + y;

            // Retourne le personnage sans modification si les coordonnées sont invalides
            if (newX < 0 || newX > maxTilesX || newY < 0 || newY > maxTilesY) {
              return character;
            }

            return {
              ...character,
              left: character.left + left,
              top: character.top + top,
              position: {
                x: newX,
                y: newY,
              },
              spriteX: character.firstStep
                ? SpriteAnimationsX.FIRST_STEP_X
                : SpriteAnimationsX.SECOND_STEP_X,
              canMove: false,
              firstStep: !character.firstStep,
            };
          }

          return character;
        }),
      };
    },
  ),

  on(CharactersMoveActions.idleStep, (state: CharacterState, { character }) => {
    const updateCharacter = (characterUpdate: Character) => ({
      ...characterUpdate,
      canMove: characterUpdate.health > 0,
      spriteX: SpriteAnimationsX.IDLE_STEP_X,
    });

    const updatedState = state.characters.map((characterUpdate: Character) =>
      characterUpdate.id === character.id &&
      characterUpdate.kind === character.kind
        ? updateCharacter(characterUpdate)
        : characterUpdate,
    );

    return {
      ...state,
      characters: updatedState,
    };
  }),

  on(
    CharactersMoveActions.setPositionXY,
    (state: CharacterState, { id, kind, x, y }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        position: { x, y },
        top: getTopCharacter(y),
        left: getLeftCharacter(x),
      });

      const updatedState = state.characters.map((character: Character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return {
        ...state,
        characters: updatedState,
      };
    },
  ),

  on(
    CharactersMoveActions.setLooking,
    (state: CharacterState, { id, kind, looking }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        spriteY: SpriteAnimationsY[looking.toUpperCase()],
        looking,
      });

      const updatedState = state.characters.map((character: Character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return {
        ...state,
        characters: updatedState,
      };
    },
  ),

  on(
    CharactersMoveActions.setFaceToPlayer,
    (state: CharacterState, { id, kind }) => {
      const updateCharacter = (character: Character) => {
        // Récupère le personnage ciblé par le focusIndex
        const focusCharacter = state.characters[state.focusIndex];

        if (!focusCharacter) return character; // Si aucun personnage n'est ciblé, on retourne l'original

        const { position: position1 } = focusCharacter;
        const { position: position2 } = character;

        if (!position1 || !position2) return character;

        // Calcule la direction du personnage à regarder
        const directions = {
          up: position1.y < position2.y,
          down: position1.y > position2.y,
          left: position1.x < position2.x,
          right: position1.x > position2.x,
        };

        const looking = Object.keys(directions).find(
          (key) => directions[key],
        ) as Looking;

        return {
          ...character,
          spriteY: SpriteAnimationsY[looking.toUpperCase()],
          looking,
        };
      };

      const updatedState = state.characters.map((character: Character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return {
        ...state,
        characters: updatedState,
      };
    },
  ),

  on(
    CharactersMoveActions.setCanMove,
    (state: CharacterState, { id, kind, canMove }) => {
      const updatedState = state.characters.map((character: Character) =>
        character.id === id && character.kind === kind
          ? { ...character, canMove }
          : character,
      );

      return {
        ...state,
        characters: updatedState,
      };
    },
  ),
];
