import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersAttackActions } from '@t12/characters/store/actions/attack/characters-attack.actions';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import {
  getPlayer,
  getPlayerID,
  getPlayerEquipmentBySlot,
} from '@t12/characters/store/selectors/characters.selectors';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { RankJobXp } from '@t12/common/job/enums/rank-job-xp.enum';
import { deathPenaltyGold } from '@t12/common/player/constants/death-penalty-gold.constant';
import { deathPenaltyXp } from '@t12/common/player/constants/death-penalty-xp.constant';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import { EquipmentsActions } from '@t12/equipments/store/actions/equipments.actions';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import {
  getFreeInventorySlotAmountByItem,
  getPlayerItemInventory,
} from '@t12/inventory/store/selectors/inventory.selectors';
import { canUseWorkshop } from '@t12/jobs/constants/can-use-workshop.constant';
import { HarvestActions } from '@t12/jobs/store/actions/harvest/harvest.actions';
import { KnowledgeActions } from '@t12/jobs/store/actions/knowledge/knowledge.actions';
import { WorkshopActions } from '@t12/jobs/store/actions/workshop/workshop.actions';
import { getPlayerJobWithCode } from '@t12/jobs/store/selectors/job.selectors';
import { HotkeyActions } from '@t12/key-commands/store/hotkey/actions/hotkey.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { getHudContainer } from '@t12/overlay/store/selectors/hud-display/hud-display.selectors';
import { delayRespawnPlayer } from '@t12/player/constants/delay-respawn-player.constant';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { SocialsFriendsActions } from '@t12/socials/store/actions/friends/socials-friends.actions';
import { SocialsGroupActions } from '@t12/socials/store/actions/group/socials-group.actions';
import { LocalPlayerSocketService } from '@t12/sockets/services/emitters/local-player-socket/local-player-socket.service';
import { SocketManagerService } from '@t12/sockets/services/listeners/socket-manager/socket-manager.service';
import { SocketService } from '@t12/sockets/services/socket.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { WorldActions } from '@t12/world/store/actions/world/world-actions';
import dayjs from 'dayjs';
import {
  map,
  switchMap,
  take,
  withLatestFrom,
  of,
  catchError,
  tap,
  delay,
  filter,
  throttleTime,
  concatMap,
} from 'rxjs';

@Injectable()
export class LocalPlayerEffects {
  private _loadPlayer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.loadPlayer),
      switchMap(() =>
        this._playerDbService.getPlayerCharacter$().pipe(
          take(1),
          switchMap((player) => [
            HotkeyActions.resetHotkeys(),
            LocalPlayerActions.loadPlayerSuccess({ player }),
          ]),
          catchError(() => of(LocalPlayerActions.loadPlayerFailed())),
        ),
      ),
    ),
  );

  private _loadPlayerFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(LocalPlayerActions.loadPlayerFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _loadPlayerSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.loadPlayerSuccess),
      concatMap(({ player }) => {
        const lastConnexionDate = dayjs(player.lastConnexion ?? new Date());

        this._socketService.initSocket(player.id);
        this._socketManagerService.init();

        return [
          HudDisplayActions.showHud({ name: 'barInfos' }),
          WorldActions.loadWorld(),
          ChatActions.explainChat({ lastConnexionDate }),
          SocialsFriendsActions.getFriends(),
          SocialsFriendsActions.getFriendRequests(),
          SocialsGroupActions.getGroup(),
          SocialsGroupActions.getGroupRequests(),
          QuestActions.getQuests(),
          InventoryActions.setInventory({ items: player.inventory }),
          HotkeyActions.loadHotkeys(),
        ].filter(Boolean);
      }),
    ),
  );

  private _localPlayerAttack$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.localPlayerAttack),
      withLatestFrom(this._store.select(getPlayerID)),
      map(([{ attackKind }, id]) =>
        CharactersAttackActions.attack({
          id,
          characterKind: CharacterKind.PLAYER,
          attackKind,
          emitDmg: true,
        }),
      ),
    ),
  );

  private _localPlayerMove$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.localPlayerMove),
      throttleTime(180),
      withLatestFrom(this._store.select(getPlayerID)),
      map(([{ direction }, id]) =>
        CharactersMoveActions.move({
          id,
          kind: CharacterKind.PLAYER,
          direction,
        }),
      ),
    ),
  );

  private _levelUp$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.levelUp),
      withLatestFrom(this._store.select(getPlayer)),
      tap(([_, player]) => {
        this._audioService.playSound('miscs', 'lvlup', 'wav');
        this._notificationService.addNotification(
          'item',
          `Vous venez de gagner un niveau ! (Lvl ${player.lvl})`,
        );
      }),
      map(([_, player]) =>
        ChatActions.addChatLog({
          tab: ChatTab.FIGHT,
          name: player.name,
          text: `a gagné un niveau ! (Lvl ${player.lvl})`,
          kind: ChatLogKind.Bonus,
        }),
      ),
    ),
  );

  private _playerDeath$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.playerDeath),
      withLatestFrom(this._store.select(getPlayer)),
      delay(delayRespawnPlayer),
      switchMap(([_, player]) =>
        this._playerDbService.playerDeath(player.id).pipe(
          take(1),
          concatMap((worldPosition) => [
            ChatActions.addChatLog({
              tab: ChatTab.FIGHT,
              name: player.name,
              text: `est mort et a perdu ${Math.ceil(
                player.gold / deathPenaltyGold,
              )} OR et ${Math.ceil(player.xp / deathPenaltyXp)} points d'expériences`,
              kind: ChatLogKind.Malus,
            }),
            LocalPlayerActions.applyDeathSanction({
              worldPosition,
            }),
            WorldActions.loadWorld(),
          ]),
          catchError(() => of(LocalPlayerActions.playerDeathFailed())),
        ),
      ),
    ),
  );

  private _playerDeathFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(LocalPlayerActions.playerDeathFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _tryEquipItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.tryEquipItem),
      filter(({ item }) => !!item.slot && !!item.stats),
      switchMap(({ item }) =>
        this._store.select(getPlayerEquipmentBySlot(item.slot)).pipe(
          take(1),
          map((oldEquipment) => {
            if (item.code === oldEquipment?.code)
              return EquipmentsActions.equipItemFailedAlreadyHave();
            else return EquipmentsActions.equipItem({ item, oldEquipment });
          }),
        ),
      ),
    ),
  );

  private _tryUnEquipItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.tryUnEquipItem),
      filter(({ item }) => !!item.slot && !!item.stats),
      switchMap(({ item }) =>
        this._store.select(getFreeInventorySlotAmountByItem(item)).pipe(
          take(1),
          map((freeAmount) => {
            if (freeAmount === 0)
              return EquipmentsActions.unEquipItemFailedNoPlace();
            else return EquipmentsActions.unEquipItem({ item });
          }),
        ),
      ),
    ),
  );

  private _pickItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.pickItem),
      throttleTime(200),
      filter(({ item }) => item.amount > 0),
      switchMap(({ item }) =>
        this._store.select(getFreeInventorySlotAmountByItem(item)).pipe(
          take(1),
          map((freeSlotsAmount) => {
            const pickedAmount = Math.min(freeSlotsAmount, item.amount);
            const canPick =
              freeSlotsAmount > 0 && freeSlotsAmount >= pickedAmount;

            if (canPick)
              return InventoryActions.pickItemSuccess({
                item: { ...item, amount: pickedAmount },
              });
            else return InventoryActions.pickItemFailedNotEnoughPlace();
          }),
        ),
      ),
    ),
  );

  private _dropItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.dropItem),
      throttleTime(200),
      filter(({ amount }) => amount > 0),
      switchMap((action) =>
        this._store.select(getPlayerItemInventory(action.itemCode)).pipe(
          take(1),
          map((item) => ({ action, item })),
        ),
      ),
      filter(({ item }) => !!item),
      map(({ action, item }) => {
        const { amount: dropAmount } = action;

        if (item.amount < dropAmount)
          return InventoryActions.dropItemFailedNotEnough();
        else if (item.linked)
          return InventoryActions.dropItemFailedItemLinked();
        else if (item.type === ItemType.Quest)
          return InventoryActions.dropItemFailedItemQuest();

        return InventoryActions.dropItemSuccess({ item, amount: dropAmount });
      }),
    ),
  );

  private _useItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.useItem),
      throttleTime(200),
      withLatestFrom(this._store.select(getPlayer)),
      switchMap(([action, player]) =>
        this._store.select(getPlayerItemInventory(action.itemCode)).pipe(
          take(1),
          map((item) => ({ action, player, item })),
        ),
      ),
      filter(
        ({ item }) =>
          item?.amount > 0 && (!!item.use || (!!item.slot && !!item.stats)),
      ),
      map(({ item, player }) => {
        if (player.health <= 0) return InventoryActions.useItemFailedDead();
        if (item.lvl > player.lvl)
          return InventoryActions.useItemFailedNotEnoughLvl();

        if (item.use?.infos) {
          return InventoryActions.consumeItem({ item });
        }
        if (item.use?.warp) {
          return InventoryActions.consumeItemWarp({
            itemCode: item.code,
          });
        }
        if (item.stats && item.slot) {
          return LocalPlayerActions.tryEquipItem({ item });
        }
        if (item.use?.learn) {
          return KnowledgeActions.learnKnowledge({ item });
        }
        return InventoryActions.useItemFailed();
      }),
    ),
  );

  private _harvest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.harvest),
      withLatestFrom(this._store.select(getHudContainer)),
      switchMap(([{ harvestPoint }, isVisibleLootBox]) =>
        this._store.select(getPlayerJobWithCode(harvestPoint.jobCode)).pipe(
          take(1),
          map((playerJob) => {
            if (!playerJob)
              return HarvestActions.harvestFailedWrongJob({
                jobCode: harvestPoint.jobCode,
              });
            else if (playerJob.xp < harvestPoint.requiredXp)
              return HarvestActions.harvestFailedRequiredXpLow({
                xp: playerJob.xp,
                requiredXp: harvestPoint.requiredXp,
              });
            else
              return isVisibleLootBox
                ? ContainerActions.pickItemContainer({ index: 0 })
                : HarvestActions.harvestSuccess({ harvestPoint });
          }),
        ),
      ),
    ),
  );

  private _openWorkshop$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.openWorkshop),
      switchMap(({ workshop }) =>
        this._store.select(getPlayerJobWithCode(workshop.jobCode)).pipe(
          take(1),
          map((playerJob) => {
            if (!playerJob)
              return WorkshopActions.openWorkshopFailedWrongJob({
                jobCode: workshop.jobCode,
              });
            else if (!canUseWorkshop(playerJob.xp)[workshop.rankJob])
              return WorkshopActions.openWorkshopFailedRequiredXpLow({
                xp: playerJob.xp,
                requiredXp: RankJobXp[workshop.rankJob],
              });
            else return WorkshopActions.openWorkshopSuccess({ workshop });
          }),
        ),
      ),
    ),
  );

  private _incJobXP$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(LocalPlayerActions.incJobXP),
        switchMap(({ jobCode }) =>
          this._store.select(getPlayerJobWithCode(jobCode)).pipe(
            take(1),
            filter((playerJob) => !!playerJob),
            tap((playerJob) => {
              this._notificationService.addNotification(
                'job',
                `Vous avez gagné 1 XP pour le métier : ${playerJob.name}`,
              );
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _playerSocketService: LocalPlayerSocketService,
    private readonly _socketService: SocketService,
    private readonly _socketManagerService: SocketManagerService,
    private readonly _store: Store,
    private readonly _worldService: WorldGeneratorService,
  ) {}
}
