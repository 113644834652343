import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import { CharactersFightActions } from '@t12/characters/store/actions/fight/characters-fight.actions';
import { getPlayer } from '@t12/characters/store/selectors/characters.selectors';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { calculateXpForNextLevel } from '@t12/common/player/constants/next-level-xp.constant';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { getQuestsInfosByKindAndCode } from '@t12/quest/store/selectors/quest.selectors';
import { WorldActions } from '@t12/world/store/actions/world/world-actions';
import { filter, map, switchMap, withLatestFrom, from, take } from 'rxjs';

@Injectable()
export class FightCharacterEffects {
  private _rewardFight$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersFightActions.rewardFight),
      switchMap(({ reward: { xp, incJob } }) =>
        from(
          [
            xp > 0 ? CharactersFightActions.rewardXp({ xp }) : null,
            incJob ? LocalPlayerActions.incJobXP({ jobCode: incJob }) : null,
          ].filter((action) => action !== null),
        ),
      ),
    ),
  );

  private _goalQuestKill$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersFightActions.rewardFight),
      filter(
        ({ target: { kind } }) =>
          kind === CharacterKind.NPC || kind === CharacterKind.MONSTER,
      ),
      switchMap(({ target }) =>
        this._store
          .select(getQuestsInfosByKindAndCode('kill', target.code))
          .pipe(
            take(1),
            map((questsInfos) => ({
              targetCode: target.code,
              questsCodes: questsInfos
                .filter(({ goals }) =>
                  goals.some(
                    (goal) =>
                      goal.kind === 'kill' && goal.amount !== goal.amountTotal,
                  ),
                )
                .map(({ code }) => code),
            })),
          ),
      ),
      filter(({ questsCodes }) => questsCodes.length > 0),
      map(({ questsCodes, targetCode }) =>
        QuestActions.updateGoals({
          questsCodes,
          goalKind: 'kill',
          amount: 1,
          entityCode: targetCode,
        }),
      ),
    ),
  );

  private _rewardXp$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersFightActions.rewardXp),
      filter(({ xp }) => xp > 0),
      withLatestFrom(this._store.select(getPlayer)),
      switchMap(([{ xp, notification = true }, player]) => {
        if (notification)
          this._notificationService.addNotification('item', `+${xp} XP`);

        const newXp = player.xp + xp;
        return [
          ChatActions.addChatLog({
            tab: ChatTab.FIGHT,
            name: player.name,
            text: `a gagné ${xp} points d'expériences`,
            kind: ChatLogKind.Bonus,
          }),
          newXp >= calculateXpForNextLevel(player.lvl)
            ? LocalPlayerActions.levelUp({ xp })
            : CharactersActions.addXp({ id: player.id, xp }),
        ];
      }),
    ),
  );

  private _rewardLoot$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersFightActions.rewardLoot),
      filter(({ container }) => container?.items.length > 0),
      map(({ container }) => {
        const { x, y } = container.position;

        return WorldActions.addContainerTile({
          x,
          y,
          container,
        });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
