import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { IT12FloorWarp } from '@t12/common/world/interfaces/t12-floor-warp.interface';

export const ExpeditionsActions = createActionGroup({
  source: 'EXPEDITION',
  events: {
    'Try launch expedition': emptyProps(),
    'Launch expedition': emptyProps(),
    'Launch expedition failed': props<{ error: HttpErrorResponse }>(),
    'Launch expedition failed member offline': emptyProps(),
    'Launch expedition failed member away': emptyProps(),
    'Launch expedition failed member already in expedition': emptyProps(),
    'Launch expedition failed member no access': emptyProps(),
    'Launch expedition failed member api error': emptyProps(),
    'Launch expedition failed no entry warp floor': emptyProps(),
    'Launch expedition success': props<{
      playerId: number;
      position: Position;
    }>(),

    'Try ascend tower': props<{ warp: IT12FloorWarp }>(),
    'Ascend tower': props<{ warp: IT12FloorWarp }>(),
    'Ascend tower failed': props<{ error: HttpErrorResponse }>(),
    'Ascend tower failed member not ready': emptyProps(),
    'Ascend tower failed monsters left': emptyProps(),
    'Ascend tower failed api error': emptyProps(),
    'Ascend tower success': props<{ position: Position }>(),

    'Exit tower': emptyProps(),
    'Exit tower failed': emptyProps(),
  },
});
