import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ITarget } from '@t12/characters/interfaces/target.interface';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { Character } from '@t12/common/characters/types/character.type';
import { QuestIcon } from '@t12/common/characters/types/quest-icon.type';
import { PlayerUpdateInfosActionSocket } from '@t12/common/player/constants/player-update-infos-action-socket.interface';

export const CharactersActions = createActionGroup({
  source: 'CHARACTER',
  events: {
    'Set characters': props<{ characters: Character[] }>(),
    'Add character': props<{ character: Character }>(),
    'Add character success': props<{ character: Character }>(),
    'Character is dead': props<{ id: number; kind: CharacterKind }>(),
    'Remove character by id': props<{ id: number; kind: CharacterKind }>(),
    'Remove character by id success': props<{
      id: number;
      kind: CharacterKind;
    }>(),
    'Reset characters': emptyProps(),
    'Delete all characters': emptyProps(),

    'Add player': props<{ id: number; position: Position }>(),
    'Add player failed': emptyProps(),

    'Add health': props<{ id: number; kind: CharacterKind; health: number }>(),
    'Add mana': props<{ id: number; kind: CharacterKind; mana: number }>(),
    'Add health mana': props<{
      id: number;
      kind: CharacterKind;
      health: number;
      mana: number;
    }>(),
    'Add xp': props<{ id: number; xp: number }>(),
    'Level up': props<{ id: number }>(),

    'Set quest icon': props<{ codeCharacter: string; questIcon?: QuestIcon }>(),

    'Player use an item': props<{
      updateInfos: PlayerUpdateInfosActionSocket;
    }>(),
    'Player use an item failed': emptyProps(),

    'Player pick an item': props<{
      containerId: number;
      itemCode: string;
      amount: number;
    }>(),

    'Set character focus': props<{ code?: string }>(),

    'Set target': props<{ target: ITarget; delayEnable?: boolean }>(),
    'Set target success': props<{ target: ITarget; delayEnable?: boolean }>(),
    'Reset target': emptyProps(),
  },
});
