import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IT12FloorWarp } from '@t12/common/world/interfaces/t12-floor-warp.interface';
import { IWorldWarp } from '@t12/common/world/interfaces/world-warp.interface';
import { IWorld } from '@t12/common/world/interfaces/world.interface';

export const TeleportActions = createActionGroup({
  source: 'TELEPORT',
  events: {
    'Trigger warp plate': props<{ warp: IWorldWarp | IT12FloorWarp }>(),
    'Handle tower warp': props<{ warp: IT12FloorWarp }>(),
    'Handle tower warp failed': emptyProps(),

    'Teleport to': props<{ scroll?: string }>(),
    'Teleport to success': props<{ world: IWorld; warp: IWorldWarp }>(),
    'Teleport to failed': emptyProps(),
    'Teleport close hud': emptyProps(),
  },
});
