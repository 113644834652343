import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import {
  getPlayer,
  getMonsters,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { ExpeditionsDbService } from '@t12/expeditions/services/expeditions-db.service';
import { ExpeditionsActions } from '@t12/expeditions/store/actions/expeditions.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import {
  areAllMembersReadyToAscend,
  isOneMemberOffline,
  isOneMemberInDifferentWorld,
} from '@t12/socials/store/selectors/socials.selectors';
import { WorldActions } from '@t12/world/store/actions/world/world-actions';
import {
  withLatestFrom,
  switchMap,
  catchError,
  of,
  tap,
  concatMap,
  map,
  take,
  filter,
} from 'rxjs';

@Injectable()
export class ExpeditionsEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _expeditionsDbService: ExpeditionsDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}

  private _tryLaunchExpedition$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ExpeditionsActions.tryLaunchExpedition),
      withLatestFrom(
        this._store.select(isOneMemberOffline),
        this._store.select(isOneMemberInDifferentWorld),
      ),
      switchMap(([_, isMemberOffline, isMemberAway]) => {
        if (isMemberOffline)
          return of(ExpeditionsActions.launchExpeditionFailedMemberOffline());
        else if (isMemberAway)
          return of(ExpeditionsActions.launchExpeditionFailedMemberAway());
        else return of(ExpeditionsActions.launchExpedition());
      }),
    ),
  );

  private _launchExpedition$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ExpeditionsActions.launchExpedition),
      withLatestFrom(this._store.select(getPlayer)),
      switchMap(([, player]) =>
        this._expeditionsDbService.launchExpedition(player.id).pipe(
          map((position) =>
            ExpeditionsActions.launchExpeditionSuccess({
              playerId: player.id,
              position,
            }),
          ),
          catchError((error) =>
            of(ExpeditionsActions.launchExpeditionFailed(error)),
          ),
        ),
      ),
    ),
  );

  private _launchExpeditionSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ExpeditionsActions.launchExpeditionSuccess),
      concatMap(({ playerId, position: { x, y } }) => [
        CharactersMoveActions.setPositionXY({
          id: playerId,
          kind: CharacterKind.PLAYER,
          y,
          x,
        }),
        CharactersMoveActions.setLooking({
          id: playerId,
          kind: CharacterKind.PLAYER,
          looking: 'up',
        }),
        HudDisplayActions.closeAllHud(),
        WorldActions.loadWorld(),
      ]),
    ),
  );

  private _launchExpeditionFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ExpeditionsActions.launchExpeditionFailed),
      switchMap((error) => {
        switch (error.error.message) {
          case 'EXPEDITION_MEMBER_OFFLINE':
            return of(ExpeditionsActions.launchExpeditionFailedMemberOffline());
          case 'EXPEDITION_MEMBER_AWAY':
            return of(ExpeditionsActions.launchExpeditionFailedMemberAway());
          case 'EXPEDITION_ALREADY_IN':
            return of(
              ExpeditionsActions.launchExpeditionFailedMemberAlreadyInExpedition(),
            );
          case 'EXPEDITION_MEMBER_MISSING_ACCESS':
            return of(
              ExpeditionsActions.launchExpeditionFailedMemberNoAccess(),
            );
          case 'NO_WARP_FLOOR':
            return of(
              ExpeditionsActions.launchExpeditionFailedNoEntryWarpFloor(),
            );
          default:
            return of(
              ExpeditionsActions.launchExpeditionFailedMemberApiError(),
            );
        }
      }),
    ),
  );

  private _launchExpeditionFailedMemberOffline$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ExpeditionsActions.launchExpeditionFailedMemberOffline),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Un des membres de l'expedition est hors ligne.",
          );
        }),
      ),
    { dispatch: false },
  );

  private _launchExpeditionFailedMemberAway$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ExpeditionsActions.launchExpeditionFailedMemberAway),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Tous les joueurs ne sont pas proches de vous (même tableau).',
          );
        }),
      ),
    { dispatch: false },
  );

  private _launchExpeditionFailedMemberAlreadyInExpedition$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          ExpeditionsActions.launchExpeditionFailedMemberAlreadyInExpedition,
        ),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Vous avez déjà une expédition en cours.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _launchExpeditionFailedMemberNoAccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ExpeditionsActions.launchExpeditionFailedMemberNoAccess),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Un membre n'a pas l'autorisation d'entrer dans la tour.",
          );
        }),
      ),
    { dispatch: false },
  );

  private _launchExpeditionFailedNoEntryWarpFloor$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ExpeditionsActions.launchExpeditionFailedNoEntryWarpFloor),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Aucun point d'entrée pour cette étage, contactez un administrateur.",
          );
        }),
      ),
    { dispatch: false },
  );

  private _launchExpeditionFailedMemberApiError$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ExpeditionsActions.launchExpeditionFailedMemberApiError),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _tryAscendTower$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ExpeditionsActions.tryAscendTower),
      withLatestFrom(this._store.select(getMonsters)),
      switchMap(([{ warp }, monsters]) =>
        this._store.select(areAllMembersReadyToAscend(warp.position)).pipe(
          take(1),
          map((allReady) => {
            if (monsters.length)
              return ExpeditionsActions.ascendTowerFailedMonstersLeft();

            if (!allReady)
              return ExpeditionsActions.ascendTowerFailedMemberNotReady();

            return ExpeditionsActions.ascendTower({ warp });
          }),
        ),
      ),
    ),
  );

  private _ascendTower$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ExpeditionsActions.ascendTower),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, id]) =>
        this._expeditionsDbService.ascendTower(id).pipe(
          take(1),
          switchMap((position) => [
            CharactersMoveActions.setPositionXY({
              id,
              kind: CharacterKind.PLAYER,
              x: position.x,
              y: position.y,
            }),
            WorldActions.loadWorld(),
          ]),
          filter(Boolean),
          catchError((error) =>
            of(ExpeditionsActions.ascendTowerFailed(error)),
          ),
        ),
      ),
    ),
  );

  private _ascendTowerFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ExpeditionsActions.ascendTowerFailed),
      switchMap((error) => {
        switch (error.error.message) {
          case 'ASCEND_FLOOR_MEMBER_MISSING':
            return of(ExpeditionsActions.ascendTowerFailedMemberNotReady());
          case 'ASCEND_FLOOR_MONSTERS_LEFT':
            return of(ExpeditionsActions.ascendTowerFailedMonstersLeft());
          default:
            return of(ExpeditionsActions.ascendTowerFailedApiError());
        }
      }),
    ),
  );

  private _ascendTowerFailedMemberNotReady$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ExpeditionsActions.ascendTowerFailedMemberNotReady),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Tous les membres doivent être sur la stèle de téléporation.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _ascendTowerFailedMonstersLeft$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ExpeditionsActions.ascendTowerFailedMonstersLeft),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Il reste encore des monstres à cette étage.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _ascendTowerFailedApiError$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ExpeditionsActions.ascendTowerFailedApiError),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Impossible de changer d'étage, contactez un administrateur.",
          ),
        ),
      ),
    { dispatch: false },
  );

  private _exitTower$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ExpeditionsActions.exitTower),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, id]) =>
        this._expeditionsDbService.exitExpedition(id).pipe(
          take(1),
          switchMap((position) => [
            CharactersMoveActions.setPositionXY({
              id,
              kind: CharacterKind.PLAYER,
              x: position.x,
              y: position.y,
            }),
            WorldActions.loadWorld(),
          ]),
          filter(Boolean),
          catchError(() => of(ExpeditionsActions.exitTowerFailed())),
        ),
      ),
    ),
  );

  private _exitTowerFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ExpeditionsActions.exitTowerFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Impossible de quitter la tour, contactez un administrateur.',
          ),
        ),
      ),
    { dispatch: false },
  );
}
