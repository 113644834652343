import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import { isPlayer } from '@t12/characters/constants/is-player.constant';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import {
  getPlayerID,
  getCharacterById,
  isLocalPlayerById,
  getPlayerPosition,
} from '@t12/characters/store/selectors/characters.selectors';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import { WorkshopActions } from '@t12/jobs/store/actions/workshop/workshop.actions';
import { getIsWorkshopOpen } from '@t12/jobs/store/selectors/job.selectors';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { getAllHudDisplay } from '@t12/overlay/store/selectors/hud-display/hud-display.selectors';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { ShopActions } from '@t12/shop/store/actions/shop/shop.actions';
import { LocalPlayerSocketService } from '@t12/sockets/services/emitters/local-player-socket/local-player-socket.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { TeleportActions } from '@t12/world/store/actions/teleport/teleport-actions';
import {
  getWorldTileAt,
  getWorldLoadingStatus,
} from '@t12/world/store/selector/world.selectors';
import {
  delay,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
  of,
  mergeMap,
} from 'rxjs';

@Injectable()
export class MoveCharacterEffects {
  private _move$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersMoveActions.move),
      switchMap((action) =>
        this._store.select(getCharacterById(action.id, action.kind)).pipe(
          take(1),
          filter((character) => !!character),
          tap(({ id }) => {
            this._playerSocketService.lookingPlayerAction(id, action.direction);
          }),
          withLatestFrom(this._store.select(getWorldLoadingStatus)),
          switchMap(([character, worldLoadingStatus]) => {
            const { direction, force } = action;
            if (
              this._worldService.canMove(
                character,
                direction,
                worldLoadingStatus,
              ) ||
              force
            ) {
              return of(
                CharactersMoveActions.moveStep({
                  character,
                  direction,
                }),
              );
            }
            return of(CharactersMoveActions.moveFailed());
          }),
        ),
      ),
    ),
  );

  private _playerMoveStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersMoveActions.moveStep),
      withLatestFrom(
        this._store.select(getIsWorkshopOpen),
        this._store.select(getAllHudDisplay),
        this._store.select(getPlayerID),
      ),
      filter(([{ character }]) => isPlayer(character)),
      switchMap(([action, workshopOpen, hudState, playerId]) =>
        this._store.select(isLocalPlayerById(action.character.id)).pipe(
          take(1),
          filter((isLocalPlayer) => isLocalPlayer),
          tap(() => {
            this._playerSocketService.movePlayerAction(
              playerId,
              action.direction,
              action.character.position,
            );
          }),
          map(() =>
            [
              hudState.dialog ? DialogActions.endConversation() : null,
              hudState.shop ? ShopActions.closeShop() : null,
              hudState.container ? ContainerActions.closeContainer() : null,
              workshopOpen ? WorkshopActions.closeWorkshop() : null,
              hudState.bank ? BankActions.closeBank() : null,
              hudState.expeditions
                ? HudDisplayActions.hideHud({ name: 'expeditions' })
                : null,
            ].filter(Boolean),
          ),
        ),
      ),
      mergeMap((actions) => actions),
    ),
  );

  private _moveIdleStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersMoveActions.moveStep),
      delay(138),
      map(({ character }) =>
        CharactersMoveActions.idleStep({ character, triggerTile: true }),
      ),
    ),
  );

  private _moveStepSound$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CharactersMoveActions.moveStep),
        tap((_) => {
          const rand = Math.floor(Math.random());
          this._audioService.playSound('footsteps', `grass_${rand}`);
        }),
      ),
    { dispatch: false },
  );

  private _teleportTo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersMoveActions.idleStep),
      filter(
        ({ character, triggerTile }) => isPlayer(character) && triggerTile,
      ),
      switchMap(({ character }) =>
        this._store.select(isLocalPlayerById(character.id)).pipe(
          take(1),
          filter((isLocalPlayer) => isLocalPlayer),
          withLatestFrom(this._store.select(getPlayerPosition)),
          switchMap(([_, position]) => {
            return this._store
              .select(getWorldTileAt(position.x, position.y))
              .pipe(
                take(1),
                filter((tile) => !!tile?.warp),
                map(({ warp }) => TeleportActions.triggerWarpPlate({ warp })),
              );
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _playerSocketService: LocalPlayerSocketService,
    private readonly _store: Store,
    private readonly _worldService: WorldGeneratorService,
  ) {}
}
